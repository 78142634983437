.btn.google{
  &{
    border: 3px solid #333333 !important;
    color: #3a3a3a;
    margin-top: 20px;
    cursor: pointer;
    padding: 17px 40px !important;
  }
  &:hover{
    border-color: #3a3a3a!important;
  }
  img{
    margin-right: 10px;
    max-height: 30px;
  }
}

.mailgun_server_selector{
  &{
    padding: 15px 15px;
  }
}


.small-font{
  &{
    font-size: 13px;
  }
}

.underline{
  &{
    text-decoration: underline;
  }
}

.sub-provider-buttons-imap{
  &{
    margin-bottom: 24px;
  }
  button{

    &{
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 5px;
      opacity: 0.8;
      display: inline-block;
      /* font-size: .875rem; */
      /* font-weight: 500; */
      line-height: 1.5;
      padding: 0.625rem 1.875rem;
      text-align: center;
      /* transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
      user-select: none;
      vertical-align: middle;
      border: 1px solid;
      width: 47%;
    }

    img{
      &{
        height: 18px;
        margin-top: 1px;
      }
    }
  }
  button:hover{
    &{
      opacity: 1;
    }
  }
  button:first-child{
    &{
      margin-right: 4%;
    }
  }
  button:last-child{
    &{
      margin-left: 2%;
    }
  }
}

.alert-no-margin.yellow.red-alert{
  &{
    margin-top: 0px!important;
    margin-bottom: 18px!important;
  }
  .fa-circle-info{
    &{
      margin-top: 3px;
    }
  }
  span{
    &{
      padding-top: 3px;
    }
  }
}

.tuto-li{
  &{
    list-style: none;
    padding: 0;
  }
  li{
    &{
      margin: 15px 0;
    }
    span{
      &{
        background: #636bfc;
        color: #fff;
        width: 20px;
        height: 20px;
        float: left;
        text-align: center;
        border-radius: 50%;
        margin-right: 10px;
        margin-bottom: 1px;
      }
    }
  }
}

.imap_only{
  .red-alert{
    &{
      margin-bottom: 28px!important;
    }
    i{
      &{
        margin-top: 17px!important;
      }
    }
  }
}

.go-back-btn{
  &{
    background: #e6e6e6;
    color: #808596;
    padding: 6px 15px;
    margin: 0;
    margin-top: 10px;
    float: left;
    border-radius: 20px;
    opacity: 0.8;
    text-decoration: none;
    margin-left: -13px;
  }
  &:hover{
    opacity: 1;
    text-decoration: none;
    color: #808596;
  }
}
.tuto-gw-btn{
  margin: 31px 0 20px;
}

.btn-gw-guide{
  margin-top: -7px;
}

.cursor-pointer{
  cursor: pointer;
}

.outlook-btn{
  &{
    background-color: #2f2f2f!important;
  }
  img{
    width: 23px;
  }
}

.google-connect-content{
  &{
    padding-bottom: 12px!important;
  }
}

.links-underline{
  a{
    text-decoration: underline;
  }
}


.imap{
  .cant-btn{
    &{
      margin-top: 15px;
      cursor: pointer;
    }
  }

  .green-alert{
    &{
      padding: 10px;
      border-radius: 4px;
      margin-top: 0px!important;
          margin-bottom: 21px;
      background: #daffe7 !important;
    }
  }

  .yellow{
    &{
      background: #fffada!important;
      margin-bottom: 10px!important;
    }
    i{
      color: #fad067!important;
    }
  }

  .yellow-outlook{
    &{
      margin-top: -14px!important;
      margin-bottom: 25px!important;
    }
  }
  .red-alert{
    &{
      background: #faecee;
      text-align: left;
      font-size: 12px;
      margin-top: 15px;
      margin-bottom: 2px;
      color: #6f7588;
    }
    i{
      color: #e15764;
      margin-right: 15px;
      margin-left: 5px;
      margin-top: 8px;
      float: left;
      font-size: 17px;
    }
    span{
      float: left;
      width: 90%;
    }
  }
  .google-btn{
    &{
      background: #4284f3;
      color: #fff!important;
      border-radius: 4px!important;
      border: 0px!important;
      opacity: 1!important;
      padding: 0!important;
    }
    .google-icon-wrapper{
      float: left;
      background: #fff;
      padding: 10px 12px;
      margin: 2px;
      border-radius: 3px;
      text-align: center;
    }
    img{
      margin: 0;
    }
    span{
      padding: 12px 16px;
      float: left;
    }
  }
}

.outlook-connect-content{
  &{
    padding-bottom: 19px;
  }
}

.gmail-help-guide{
  height: 293px;
  padding: 50px 20px;
}
.max-width{
  &{
    max-width: 100%;
  }
}

.card-big{
  &{
    width: 48.5%!important;
  }
}

.code-share-team {
    max-width: 456px;
}

.help-modal{
  &{
    font-size: 18px;
    margin-top: 100px;
  }
  .modal-dialog{
    max-width: 700px;
  }
  p{
    margin: 0 0 15px;
  }
}
.config-ico{
  &{
    margin-top: 4px;
    font-size: 20px;
    padding: 0;
    height: 27px;
  }
}

.config-title{
  &{
    padding: 17px;
  }
}

.provider{
  &{
    margin-bottom: 30px;
  }
  .card{
    &{
      cursor: pointer;
      border: 2px solid #fff;
      width: 23%;
      margin: 2% 1%;
      margin-bottom: 0;
      text-align: center;
      padding: 20px;
      max-height: 71px;
    }
    &.selected{
      border: 2px solid #6742ed;
    }
    img{
      max-width: 30px;
      border-radius: 4px;
    }
    .name{
      &{
        margin-left: 10px;
        font-size: 14px;
        float:left;
        padding-top: 6px;
      }
    }
  }
}

.li-margin{
  li{
    &{
      margin: 16px 0;
    }
    a{
      text-decoration: underline;
    }
  }
}

.reputation-modal.help-modal{
  .modal-dialog{
    &{
      max-width: 740px;
    }
  }
}

.btn{
  opacity: 0.8;
}

.btn:hover{
  opacity: 1!important;
}

.list-number{
  &{
    list-style: auto;
  }
}

.imap{
  select{
    &{
      margin-bottom: 0;
    }
  }
  form{
    .fieldCont{
      border-bottom: 0;
      margin-bottom: 20px;
    }
    .btn{
      &{
        color: #fff;
        background-color: #3d46fb;
        border-color: #3d46fb;
      }
    }
    input{
      &{
        margin: 0;
        width: 100%;
        padding: 12px;
        font-size: 16px;
        color: #18164c;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e0e5e9;
        border-radius: 4px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      }
      &:active, &:focus{
        border: 1px solid!important;
        outline-offset: 0!important;
        outline: 0!important;
      }
    }
  }
}

form{
  select{
    &{
      margin-bottom: 0;
    }
  }
  .imap{
    .fieldCont{
      border-bottom: 0;
      margin-bottom: 20px;
    }
    .btn{
      &{
        color: #fff;
        background-color: #3d46fb;
        border-color: #3d46fb;
      }
    }
    input{
      &{
        margin: 0;
        width: 100%;
        padding: 12px;
        font-size: 16px;
        color: #18164c;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e0e5e9;
        border-radius: 4px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      }
      &:active, &:focus{
        border: 1px solid!important;
        outline-offset: 0!important;
        outline: 0!important;
      }
    }
  }
}



.descprice{
  img{
    max-width: 100%
  }
  .col-md-11{
    &{
      font-size: 19px;
      padding-top: 15px;
    }
  }
}

.no-left-margin{
  margin-left: 0!important;
}

.no-right-margin{
  margin-right: 0!important;
}

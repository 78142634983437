.cockpit .cockpit-block-container .no-results.no-account-issues img {
    max-width: 150px;
    margin: 0 auto;
    margin-top: 20px!important;
}

.cockpit{

  .text-align-left{
    &{
      text-align: left;
    }
  }
   .col-md-12{
    &{
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .cursor-pointer{
    &{
      cursor: pointer;
    }
  }
  .notifs-priority{
    &{
      margin-top: 20px;
    }

    .severity{
      &{
        float: right;
        background: #ff1d51;
        color: #fff;
        padding: 2px 7px;
        border-radius: 18px;
        margin-right: -6%;
      }
    }

    .title{
      &{
        padding: 0px 15px 12px;
        font-size: 13px !important;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
    .card{
      &{
        height: auto;
        margin-bottom: 10px;
        padding: 15px 0px 0px;
      }
      .alert{
        &{
          padding: 10px 15px!important;
          cursor: pointer;
        }
        &:hover{
          background: #f2f2f2!important;
        }
        &.recipe{
          &{
            color: #6f7588!important;
          }
        }
        .btn{
          &{
            background: #ccc;
            background-color: #f4f6f9;
            border: 1px solid #e0e5e9;
            box-shadow: 0 2px 4px 0 rgba(1,1,2,.05);
            color: #6f7588;
          }
        }
      }
      .cockpit-block-container{
        &{
          overflow: hidden;
        }
      }
    }

    .alert.status{
      &{
        width: 100%;
        margin: 0;
        padding: 10px 0px;
      }

      .btn.pull-right{
        &{
          padding: 2px 16px!important;
          margin-top: -2px;
        }
      }

      .txt{
        &{
          margin-top: 1px;
          padding: 0;
          width: 70%;
        }
      }
    }
  }

  .avg-score{
    .deliverability-score{
      &{
        background: transparent;
      }
      &.good{
        &{
          color: #00d463;
        }
      }
      &.bad{
        &{
          color: #ff1e51;
        }
      }
      &.stable{
        &{
          color: #bebebe;
        }
      }
    }
  }


  .cockpit-block-container{
    &{
      &{
        max-height: 290px;
        width: 100%;
        overflow-y: scroll;
      }
    }

    .bot{
      &{
        float: left;
        margin: 0;
        height: 10px;
        margin-top: -8px;
        margin-left: 10px;
      }
    }
    a{
      &{
        float: left;
      }
    }
    .line{
      &{
            padding: 0px 15px;
      }
    }
    .line:hover{
      &{
        background: #f2f2f2;
      }
    }
    .card{
      &{
        box-shadow: none;
        height: auto;
        border-bottom: 1px solid #ebebeb;
        border-radius: 0;
        background: none!important;
        color: #6f7588;
      }

      .close{
        display: none;
      }

      .txt{
        &{
          padding-left: 0;
          width: 100%;
          margin-top: 0;
          margin: 0;
          padding: 10px 0px;
          color: #6f7588;
        }
      }
    }

    .no-results{
      &{
        text-align: center;
      }
      img{
        &{
          max-width: 150px;
          margin: 0 auto;
          margin-top: 58px;
        }
      }
      &.tags, &.no-account-issues{
        &{
          max-width: 70%;
          margin: 0 auto;
          margin-top: 40px;
          float: none;
        }
        img{
          &{
            margin-top: 0;
          }
        }
      }
    }
  }

  .table-head{
    &{

    }
    .score{
      &{

      }

      .fa-solid{
        &{
          margin-left: 7px;
          cursor: pointer;
        }
      }
    }
  }

  .card{
    &{
      height: 339px;
    }
    .col-md-12{
      &{
        margin-top: 0px!important;
        margin-bottom: 0px!important;
      }
    }
  }
  .domains{
    &{
      max-height: 356px;
      padding: 15px 0;
      height: auto;
    }
    .table-head{
      &{
        border-bottom: 1px solid #ebebeb;
        padding: 0px 15px 12px;
      }
      div{
        &{
          float: left;
          font-size: 13px !important;
          text-transform: uppercase;
          font-weight: 500;
        }
      }
      .score{
        width: 15%;
      }
      .account{
        &{
          width: 70%;
        }
      }
    }
    .line{
      .score{
        width: 15%;
      }
      .account{
        &{
          width: 70%;
          color: #6f7588;
          font-size: 15px;
          padding: 11px 0px;
        }
        a{
          &{
            color: #6f7588!important;
          }
          &:hover{
            text-decoration: underline;
          }
        }
      }
      &{
        border-bottom: 1px solid #ebebeb;
        width: 100%;
        display: flex;
      }
      div{
        float: left;
      }
    }
  }
}

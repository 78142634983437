@import '../../constants.scss';


@media only screen and (max-width: 470px) {
  .ct{
    width: auto!important;
  }
  .signup .ct {
      max-width: 90%!important;
      margin: 0 auto!important;
  }

  .otherLinks {
    margin-bottom: 20px;
  }

  .levitate .feedback{
    &{
      width: 100%;
      margin-left: 0!important;
    }
  }
  .tosTxt{
    &{
      margin-bottom: 50px;
    }
  }

  footer ul{
    &{
       padding-left: 0;
    }
  }
}

.pointer{
  &{
    cursor: pointer;
  }
}

.review-all{
  &{
    width: 100%;
  }
  .review-hero-content{
    &{
      width: 44%;
      min-width: 600px;
      margin: 0 auto;
    }
  }
  .star-icon {
    width: 24px;
    height: 24px;
  }
  .star-icon-wrap {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .review-hero-item-image {
    width: 100%;
    height: 48px;
    margin: 15px 0;
  }
  .review-hero-item-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
  }
  .review-hero-item-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
  }
}

@media only screen and (max-width: 1700px) {
  .review-card{
    .paragraph-18px{
      height: 150px!important;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .review-card{
    .paragraph-18px{
      height: 210px!important;
    }
  }
}

@media only screen and (max-width: 992px) {
  .review-all{
    padding: 0!important;
    float: left;
  }

  .hidden-light{
    float: left;
  }

  .review-hero-content{
    float: left;
  }
  .review-hero-item{
    margin-top: 20px;
    padding: 50px 0;
    float: left;
    width: 100%;
  }

  .margin-left{
    &{
      margin-left: 2%!important;
    }
  }

  .review-hero-content{
    width: 100%!important;
    min-width: 100%!important;
  }
}


@media (min-width: 470px) and (max-width: 1300px) {
  .feedback{
    &{
      margin-left: 1%!important;
    }
    &.n{
      margin-left: 10%!important;
    }
    &.x{
      margin-left: 6%;
    }
  }
}

.tosTxt{
  &{
    margin-bottom: 35px;
    font-family: SF Pro Text, sans-serif;
  }
  a{
    &{
      color: #6e62ff;
      font-weight: 500;
      font-family: SF Pro Text, sans-serif;
    }
  }
}

@media (min-width: 470px) and (max-width: 1700px) {
  .hidden-light{
    visibility: hidden;
    height: 0;
  }
}

#user_tos{
  &{
    margin-top: 0;
  }
}
.feedback{
  &{
    margin-bottom: 40px;
    max-width: 550px;
    padding: 20px;
    background: #fff3;
    border-radius: 16px;
    border: 1px solid #ffffff69;
    margin-left: 7%;
  }
  &.n{
    margin-left: 25%;
  }
  &.x{
    margin-left: 17%;
  }
  .avatar{
    &{
      float: left;
      margin-top: 15px;
    }
    img{
      border-radius: 50%;
      width: 110px;
      margin-bottom: 0;
      opacity: 0.9;
    }
  }
  .cont{
    &{
      padding-left: 131px;
      text-align: left;
    }
    .review{
      &{
        font-size: 14px;
        font-weight: 400;
      }
    }
    .from-n{
      margin-top: 10px;
      font-size: 20px;
      font-weight: 500;
      color: #000;
    }
    .from-p{
      opacity: 0.8;
    }
  }
}
.red-alert{
  &{
    font-size: 13px;
    background: #ff4d66;
    color: #fff;
    padding: 10px;
    text-align: center;
    margin-top: 17px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
}

@media only screen and (max-width: 680px) {
  .teaser{
    .hidden-mobile{
      &{
        display: none;
      }
    }
  }
  .sign{
    h1{
      &{
        font-size: 27px!important;
      }
    }
    .ct{
      &{
        margin: 0 auto!important;
        padding-top: 35px;
      }
    }
  }
}

.teaser{
  &{
    background: #11103c;
    height: 100vh;
    color: #fff;
    font-family: SF Pro Text, sans-serif;
    padding-top: 4%;
    overflow: hidden;
  }

  .cta-pattern-bg {
    z-index: 0;
    object-fit: cover;
    object-position: 50% 0%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

  .review-all{
    &{
      border-radius: 8px;
      margin-top: 4%;
      height: 100px;
    }
    img{
      max-height: 100%;
    }
  }

  .review-card{
    &{
      background-color: #fff;
      border-radius: 10px;
      padding: 24px;
      margin: 2%;
    }

    .star-icon-wrap{
      &{
        width: 200px;
        padding: 7px 0px 20px 0px;
      }
      img{
        &{
          margin: 0 5px 0px 0px;
        }
      }
    }
    &.margin-left{
      &{
        margin-left: 6%;
      }
    }
    p{
      color: #04121f;
      font-size: 15px;
      height: 120px;
      font-family: SF Pro Text, sans-serif;
    }
    .stars{
      &{
        margin-bottom: 20px;
      }
    }
    .author-img-small {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    float: left;
    margin-right: 20px;
}
    .author-contain {
      align-items: center;
      width: 100%;
  }
  .author-name {
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    color: #04121f;
}
.author-position {
    opacity: .6;
    font-weight: 500;
      color: #04121f;
}
  }
}

.signup{
  &{
    background: #fff;
    height: 100vh;
  }
  .ct{
    max-width: 500px;
    margin: 0 auto;
    margin-top: 3%;
  }
  h1{
    line-height: 30px;
  }
}

@keyframes levitate {
  0% {
    transform: translateY(8px) rotate3d(0, 0, 1, 0deg); }
  100% {
    transform: translateY(-8px) rotate3d(0, 0, 1, 0deg); } }

.levitate{
  &{
    margin-top: 30px;
  }
  p{
    font-size: 19px;
    line-height: 28px;
    text-align: justify;
    margin-bottom: 20px;
  }
  .ico{
    &{
      margin-left: 5%;
      margin-right: 5%;
      float:left;
      width: 120px;
      height: 120px;
      box-shadow: 0 12px 24px 0 rgba(1, 1, 2, 0.08);
      background-color: #fff;
      background: #fff;
      border-radius: 50%;
      float: left;
      animation: levitate;
      animation-duration: 4s;
      /* animation-timing-function: ease-in-out; */
      animation-play-state: running;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    &.to{
      margin-top: 5%;
    }
    &.tz{
      margin-left: 12%;
    }
    img{
      max-width: 71%;
      margin: 0 auto;
      border-radius: 50%;
      margin-top: 18px;
      margin-left: 16px;
    }
  }
}

.market-container{
  &{
    text-align: center;
    margin-bottom: 40px;
  }
  img{
    width: 230px;
  }
}

  .invite-b{
    img{
      width: 84px;
      border-radius: 50%;
      border: 4px solid #fff;
      -webkit-box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
      box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    }
    p{
      padding: 15px;
    }
  }

  form fieldset {
    border: 1px solid rgba(19,20,49,.1);
    padding: 0;
    margin: 0;
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0,0,0,.03);
    width: 100%;
    transition: all .2s;
}
form .fieldCont {
    position: relative;
    border-bottom: 1px solid rgba(19,20,49,.1);
}

form input[type=email], form input[type=password], form input[type=text], form input[type=url] {
    border: 0;
    outline: none;
    font-size: 15px;
    width: 100%;
    display: block;
    padding: 25px 15px;
    margin-bottom: 0px!important;
    background: hsla(0,0%,100%,0);
    transition: transform .2s;
}
form label.over {
    position: absolute;
    top: 26px;
    left: 15px;
    transition: all .2s;
    pointer-events: none;
}
form label {
    font-size: 14px;
    font-weight: normal;
    color: rgba(19,20,49,.4);
}
form .fieldCont.last {
    border-bottom: 0;
}
form .formActions {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

form .formActions.tosTxt{
  height: 17px;
}

form .buttonCont {

}
.authSeparator span {
    background: #fff;
    color: #9e9e9e;
    position: relative;
    top: -31px;
    margin: 100px 0;
    padding: 0 15px;
}
.authSeparator hr {
    background: #c1c1c1;
    border: none;
    height: 1px;
    margin: 30px 10px 20px;
}
.submit {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #27b970!important;
  font-weight: 500!important;
  padding: 19px 20px;
  font-size: 13px;
  border-radius: 40px!important;
}
form#google_sign_in button {
    background-size: 50px;
    border: 1px solid rgba(19,20,49,.1);
    box-shadow: 0 10px 20px rgba(0,0,0,.03);
    border-radius: 5px;
    width: 100%;
    text-align: left;
    padding: 25px 20px 25px 80px;
    font-size: 1.1em;
    color: #6d6d6d;
    transition: background-color .1s ease-in-out;
}
.authSeparator{
  text-align: center;
  margin-top: -5px;
}
.otherLinks{
  margin-top: 30px;
  text-align: center;
  font-family: SF Pro Text, sans-serif;
}
.otherLinks a {
    margin: 0 5px;
    color: #6e62ff;
    font-weight: 600;
    transition: color .2s;
    text-decoration: none;
    font-family: SF Pro Text, sans-serif;
}

input:focus {
  outline-offset: -2px;
}

form input.filled+label, form input:focus+label {
  top: 4px!important;
  font-size: 12px;
  color: rgba(19,20,49,.4);
}

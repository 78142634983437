
.normal-weight{
  &{
    font-weight: normal!important;
  }
}

.billing-home-v2{
  &{

  }

  .btn.add-cb{
    &{
      margin-right: 11px;
      margin-top: 13px;
    }
  }

  .invoices{
    .btn{
      &{
        margin-top: -2px;
      }
    }
    &.invoices-list{
      li{
        &{
          border-bottom: 1px solid #cccccc9c;
          padding: 20px 0;
        }
      }
    }
  }
  .no-padding-left{
    &{
      padding-left: 0!important;
    }
  }
  .col-md-8{
    &{
      width: 100%;
      background: #fff;
      border-radius: 8px;
      padding: 12px 40px;
    }
    .row{
      &{
        margin: 0;
      }
    }
    .card{
      &{
        box-shadow: none;
        padding: 0;
        padding-bottom: 22px;
        border-bottom: 1px solid #cccccc9c;
        border-radius: 0 !important;
      }
    }

    .card.last{
      &{
        border-bottom: 0px!important;
        padding-bottom: 11px;
      }
    }

    h2{
      padding-top: 15px!important;
      margin-top: 0px;
      padding-left: 0px!important;
    }
  }
  .credit-balance{
    &{
      padding-top: 38px;
      font-size: 16px;
    }
  }
  .col-md-8 h2.h-credit-balance{
    &{
      padding-top: 36px !important;
      padding-bottom: 0px;
      margin-bottom: 12px;
    }
  }
}

.select-billing-period{
  &{
    float: left;
    width: 100%;
    margin-top: 10px;
  }
  span{
    &{
      //float: left;
    }
  }
  .MuiFormGroup-root{
    &{
      float: left;
      margin-top: -8px;
      margin-left: 17px;
    }
  }
}

.customer-details{
  .line{
    width: 100%;
    margin: 10px 0;
  }
  .value{
    font-weight: 500;
  }
}

#dsauth-overlay{
  &{
    position: fixed;
    background: #000000a6;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 88;
    display: none;
  }
}

#dsauth{
  z-index: 999999;
  position: fixed;
  top: 12%;
  left: 31%;
  display: none;
}

.StripeElement {
  box-sizing: border-box;

  height: 50px;

  padding: 15px;
  border-radius: 4px;
  background-color: white;

  border: 1px solid #e0e5e9;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  margin: 0px 0px 0px 0px!important;
}

.StripeElement--focus {
  border-color: #18164c;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.MuiSlider-thumb{
  &{
    height: 15px!important;
    width: 15px!important;
    background: #11103b!important;
  }
}

.btn.red.btn-cancel-subscription{
  color: #ff1d51;
  background: #ccc!important;
  border: 1px solid #e0e5e9!important;
  background-color: #f4f6f9!important;
  box-shadow: 0 2px 4px 0 rgba(1, 1, 2, 0.05);
}


.emailvalidation-billing-page{

  .selected{
    &{
      color: #18164c;
    }
  }
  table{
    &{
      margin-top: 13px;
      margin-bottom: 13px;
    }
    th{
      &{
        border-bottom: 0!important;
        padding-left: 20px!important;
      }
    }
    tr{
      td{
        border-top: 0;
        padding-left: 20px;
      }
    }
  }

  .select-billing-period{
    &{
      float: left;
      width: 100%;
      margin-top: 10px;
    }
    span{
      &{
        //float: left;
      }
    }
    .MuiFormGroup-root{
      &{
        float: left;
        margin-top: -8px;
        margin-left: 17px;
      }
    }
  }

  .cancel-anytime{
    &{
      font-weight: 300!important;
      font-size: 14px!important;
      opacity: 0.6;
    }
  }


  .plans .plan {
    width: 50%;
    border: none;
    margin: 0
  }

  .plans .plan .selectplan {
    padding: 13px 0 10px 0!important;
  }

  table{
    &{
      background: #f6f6f6;
      padding: 10px;
      border-radius: 6px;
      cursor: pointer;
    }
  }

  .range-slider{
    &{
      margin-top: 5px;
      padding: 5px 15px!important;
    }
  }

  .btn.purple-btn.green{
    &{
      padding: 13px 26px!important;
      margin-top: 10px;
    }
  }

  .imap{
    &{
      margin-top: 20px;
      float: left;
      width: 100%;
    }

    .fieldCont{
      &{
        padding: 0px 55px;
      }
    }
    input{
      &{
        text-align: center;
      }
    }
  }
}

.yearly-total{
  &{
    float: left;
    width: 100%;
    margin-top: -10px;
    margin-bottom: 13px;
  }
}

.mailboxes-increase{
  &{
    float: left;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  input{
    width: 50%!important;
    float: right;
    padding: 8px 12px!important;
    margin-top: 2px!important;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
     opacity: 1;
  }

  span{
    &{
      font-size: 17px;
      margin-top: 11px;
      float: left;
      width: 50%;
    }
  }
}

.default-payment{
  &{
    border: 1px solid;
    padding: 4px;
    border-radius: 4px;
    color: #3d46fb;
    margin-left: 30px;
    margin-bottom: 1px;
    display: inline-block;
  }
}


.no-padding{
  &{
    padding: 0!important;
  }
}
.btn.add-cb{
  &{
    margin-top: 23px;
    font-size: 12px!important;
    padding: 5px 19px!important;
  }
}


.seats-checkout{
  &{
    margin-bottom: 35px;
  }
  .plans{
    .plan{
      width: 50%;
      margin-left: 24%;
    }
  }

  .money{
    &{
      font-size: 20px!important;
      height: 10px;
      position: relative;
      top: -20px;
    }
  }

  .period{
    &{
      font-size: 12px!important;
      opacity: 0.5;
    }
  }

  .MuiFormGroup-root{
    &{
      display: contents;
    }
    label{
      &{
        width: 44px;
        margin-left: 0px;
      }
    }
  }

  .select-billing-period{
    &{
      padding: 30px;
      font-size: 16px;
    }
    .discount{
      &{
        color: #00c217;
        font-size: 13px;
        background: #e4fae5;
        padding: 3px 5px;
        border-radius: 5px;
        right: 25px;
      }
    }
    .selected{
      &{
        font-weight: 500;
      }
    }
    span{
      cursor: pointer;
    }
  }
}
.explain-billing{
  h2,p{
    &{
      width: 100%;
    }
  }
  h2{
    &{
      padding: 0!important;
      margin-top: 5px;
    }
  }
}



@media only screen and (min-width: 470px) {
  .checkout{
    &{
      position: relative;
    }
  }
  .sticky-payment{
    &{
      height: 1400px;
      right: 0;
      position: absolute;
      top: 51px;
      padding-right: 0;
    }
    .card{
      &{
        position: sticky;
        top: 70px;
      }
    }
  }
}


.how-billing-works-link{
  &{
    font-size: 13px;
    margin-left: 10px;
  }
}

.card{
  .invoices{
    i{
      font-size: 15px;
    }
    .fa-info-circle{
      &{
        font-size: 15px;
      }
    }
    span{
      margin-left: 20px;
      font-size: 15px;
      font-weight: 500;
      margin-right: 40px;
    }
    .btn-primary.red, .btn-secondary{
      &{
        font-size: 12px!important;
        padding: 5px 20px !important;
      }
    }
    .btn-secondary{
      &{
        margin-right: 10px;
      }
    }
  }
}

.add-credit-card{
  .descprice{
    .col-md-11{
      &{
        padding-top: 0;
      }
    }
    p{
      font-size: 14px;
      margin: 0;
    }
  }

  .secure-payment{
    &{
      background: #d6ffe6;
      padding: 12px;
      color: #191919;
      border-radius: 4px;
      margin-bottom: 30px;
    }
  }

  .secure-payment.yellow{
    color: #6f7588!important;
  }

  h2{
    padding: 22px;
  }
}

.subscribe{
  &{
    margin: 0 auto;
  }
  .btn{
    color: #fff;
    background-color: #3d46fb;
    border-color: #3d46fb;
    opacity: 1;
  }
  .success-p{
    font-size: 17px;
    padding: 30px;
  }
}
.unlimited{
  &{
    font-size: 35px;
    color: #3d46fb;
    border-color: #3d46fb;
    border: 1px solid;
    padding: 20px;
    border-radius: 10px;
    /* float: left; */
    display: inline-block;
    margin-top: 16px;
  }
}
.free{
  &{
    font-size: 20px;
    color: #18174e;
    border-color: #18174e;
    border: 1px solid;
    padding: 10px;
    border-radius: 10px;
    /* float: left; */
    display: inline-block;
    margin-top: 0px;
  }
}
.nav-left{
  &{
    list-style: none;
    padding: 0;
  }
  li{
    &{
      padding: 5px;
      float:left;
      width: 100%;
    }
    i{
      margin-right: 5px;
    }
    a{
      &{
        font-weight: 400;
        font-size: 15px;
        color: #6f7588;
      }
      &:hover{
        text-decoration: none;
      }
    }
    .signout:hover{
      color: #ff1d51;
    }
  }
}

.invoices{
  &{
    list-style: none;
    padding: 0;
  }
  li{
    &{
      width: 100%;
      float:left;
      padding: 10px;
      border-bottom: 0;
    }
    a{
      font-weight: bold;
    }
  }

  .red-txt{
    &{
      color: red;
    }
  }
}

.invite-link{
  &{
    width: 100%;
    margin-bottom: 10px;
  }
}

.copy-code{
  .code{
    max-width: 700px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.team{
  .email-code, .cde{
    &{
      background-color: #fff!important;
      color: #11103b!important;
      border-radius: 8px;
      margin: 15px 0px;
      font-size: 15px;
      cursor: pointer;
      display: inline-flex;
      margin-top: 15px;
      font-weight: 500;
      border: 1px solid #ccc;
      max-width: 100%;
    }
    .code, .list{
      padding: 12px;
    }
    .copy{
      &{
        float: left;
        font-size: 16px;
        margin-left: 0px;
        border-left: 1px solid #ccc;
        background: #f8f9fb;
        color: #10113b;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 10px 16px;
      }
    }
  }
}

.team-list{
  ul{
    &{
      margin: 0;
      padding: 0;
    }
    li{
      &{
          padding: 15px 0;
          float: left;
          width: 100%;
          list-style: none;
          border-bottom: 1px solid rgba(0,0,0,.050980392156862744)!important;
      }
      .user-infos{
        &{
          float: left;
          margin-top: 10px;
          width: 90%;
        }
        .btn{
          color: #fff;
          margin-top: -6px;
          padding: 5px 20px!important;
        }
      }
    }
  }
  .team-pp{
    &{
      height: 40px;
      width: 40px;
      font-size: 19px !important;
      border-radius: 50%;
      background-color: #3d46fb !important;
      color: #fff;
      text-align: center;
      padding-top: 6px;
      float: left;
      margin-right: 15px;
      padding: 6px 14px !important;
    }
  }
}

.MuiSlider-thumb{
  .fas{
        color: #fff;
  }
}


.plans{
  .clcsq{
    padding: 10px 10px 50px 10px !important;
  }
}


.add-credit-card .cancel-btn{
  padding: 30px 0 10px;
}


.add-seats-slider{
  .MuiSlider-rail{
    color: #ccc!important;
  }
}
.plans .plan{

  .downgrade{
    background-color: #ccc!important;
  }

  .current{
    border: 1px solid;
    background-color: transparent!important;
    color: #000!important;
  }

  &{
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 0 1%;
    width: 31%;
    cursor: pointer;
  }

  .green{
    background-color: #26c180!important;
  }

  .MuiSlider-rail{
    color: #ccc!important;
  }

  .MuiSlider-colorSecondary{
    &{
      //color: #7a7a7a;
    }
  }
  .range-slider{
    &{
      padding: 0px 5px;
    }
    p{
      font-size: 11px;
      margin-top: -10px;
    }
  }
  &.popular{
    border: 2px solid #11103b;
  }
  .pricing{
    &{
      font-size: 17px;
      padding: 10px;
      float: left;
      width: 100%;
      color: #11103b;
    }
    span{
      font-weight: 500;
      font-size: 50px;
      margin-right: 5px;
    }
  }
  h2{
    padding: 0;
    text-align: center;
    width: 100%;
      color: #11103b;
  }
  .selectplan{
    padding: 13px 0 27px 0;
  }
  .features{
    &{
      list-style: none;
      text-align: left;
      padding: 0;
    }
    li{
      &{
        line-height: 30px;
        font-size: 13px;
        color: #11103b;
      }
    }
    i{
      margin-right: 10px;
      color: #26c180;
    }
  }
}

.checkout-recap{
  &{
    float: left;
    width: 100%;
    padding: 25px;
    margin-bottom: 20px;
    background: #f1f1f1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #ccc;
  }
}

.chkt{
  &{
    padding: 0!important;
  }
  .checkout{
    padding: 35px;
  }
}

.invoice{
  .line{
    &{
      line-height: 24px;
    }
    span{
      font-weight: 500;
    }
  }
  .details{
    &{
      margin-bottom: 30px;
    }
  }

  .total{
    &{
      font-size: 20px;
      margin-bottom: 25px;
      padding-top: 25px;
      border-top: 1px solid #ccc;
      margin-top: 25px;
    }
  }
}

.checkout{
  select{
    padding: 13px;
  }
  label{
    font-weight: 500!important;
  }
}

.alert-trigger label{
  &{
    margin-right: 20px;
  }
}

.alert-trigger{
  input{
    &{
      width: 30px!important;
      margin-top: 2px!important;
    }
  }
  .input-label{
    &{
      margin: 11px;
      margin-left: 0;
      float: left;
      font-size: 16px;
      width: 100%;
    }
  }
}


.alerts-page{
  .beta{
    &{
      position: absolute;
      background: #11113b;
      color: #fff;
      padding: 3px 5px;
      font-size: 14px;
      border-radius: 4px;
      margin-left: 10px;
      margin-top: 6px;
    }
  }
  &>.col-md-9{
    &{
      padding-top: 53px;
    }
  }
  .purple-btn{
    &{
      margin: 25px 25px 30px 0px;
    }
  }
  h2.block-title{
    margin-top: 10px;
  }

  .card table{
    &{
    }
    .btn-secondary, .btn-primary{
      &{
        padding: 0px 20px!important;
      }
    }
    td{
      &{
        //padding: 2px;
      }
      &.slider{
        &{
          padding: 2px;
        }
      }
    }
    .btn-primary{
      &{
        margin-right: 13px;
      }
    }
  }
}

.edit-tag-modal{
  .slack-incentive{
    &{
      margin-top: 5px;
    }
    h2{
      &{
        font-size: 18px;
      }
    }
    a{
      margin-top: 0px!important;
    }
    svg{
      &{
        width: 90px;
        margin: 24px 0px 0px;
      }
    }
    .green-bck {
      background-color: #26c180!important;
    }
  }
}


.alert-modal{
  &{
    margin-top: 0;
  }
  .modal-content{
    &{
      width: 740px;
    }
  }
  .modal-dialog{
    &{
      margin-top: 180px;
    }
  }
  select{
    &{
      height: 48px;
      padding: 12px;
    }
  }
}
.edit-tag-modal{
  .alert.card.bounce{
    &{
      margin-top: 0px!important;
    }
  }
}

.no-results.alerts{
  &{
    float: left;
    max-width: 100%;
    width: 100%;
    text-align: center;
    padding-bottom: 30px;
  }
  img{
    max-width: 150px;
    margin: 0 auto;
  }
}

.slack-incentive{
  &{
    background-color: #4a154b!important;
    width: 100%;
    color: #fff;
    padding: 4px 10px 16px 16px!important;
  }
  h2{
    padding-left: 0!important;
    margin: 0;
    width: 100%;
    padding: 22px 10px;
    color: #fff;
  }
  .btn-primary{
    &{
      opacity: 1!important;
    }
  }
  svg{
    &{
      width: 100px;
      margin: 17px 0px;
    }
  }
}

.card .btn{
  &{
    margin-top: 2px;
  }
}
.status.success{
  &{
    background: #47e4b2;
    color: #fff;
  }
  .btn{
    &{
      background: #0000007d;
    }
    &:hover{
      background: #000000b8;
      color: #fff;
    }
  }
}




.add-account-modal{
  &.new-seats{
    &{

    }
    .modal-content{
      &{
        width: 600px;
      }
    }
    .modal-dialog{
      &{
        width: 600px;
      }
    }

    .MuiSlider-thumb{
      &{
        margin-top: 0px!important;
      }
    }
  }
  .add-seats-slider{
    &{
      padding: 20px 0px;
    }
  }
  .desc-seat{
    &{
      font-weight: 500;
    }
  }

  .usage-desc{
    &{
      font-size: 15px;
    }
  }

  .add-seats-desc{
    &{
      font-size: 12px;
    }
  }
}

#clearfix{
  &{
    clear: both;
  }
}

.filters.btn-batch-edit{
  &{
    width: auto;
    margin: 0;
  }
  .dropdown-toggle.btn{
    &{
    }
  }
}

.taxes{
  &{
        font-size: 12px;
  }
}

.dropdown-div-open{
  &{
    margin-top: -5px!important;
    top: -5px!important;
  }
}

.select-alert-batch-edit{
  &{
    padding: 10px;
  }
}

.compressed-view{
  &{
    margin: 0 15px;
    box-shadow: 0 7px 14px 0 rgb(60 66 87 / 8%), 0 3px 6px 0 rgb(0 0 0 / 12%);
  }
  .col-md-12{
    &{
      margin: 0;
      padding: 0;
      border-bottom: 1px solid #ebebeb;
    }
  }

  > :nth-child(1){
    &{
      border-bottom: 0px solid;
    }
  }

  > :nth-child(2) .card{
    &{
      border-top-left-radius: 10px!important;
      border-top-right-radius: 10px!important;
    }
  }
  > :last-child{
    &{
      border-bottom: none;
    }
  }

  > :last-child .card{
    &{
      border-bottom-left-radius: 10px!important;
      border-bottom-right-radius: 10px!important;
    }
  }

  .card:hover, .card.selected{
    &{
      background: #f2f2f2;
    }
  }

  .selector{
    &{
      float: left;
          padding: 13px 0px 10px 10px;
    }
  }

  .card{
    .account:hover{
      a{
        color: #000;
        text-decoration: underline;
      }
    }
  }
  .card{
    &{
      margin: 0!important;
      padding: 0;
      border-radius: 0;
      box-shadow: none;
    }
    .status{
      &{
        width: 13%;
        margin-left: 1%!important;
        margin: 0!important;
      }
    }
    .btn{
      &{
        padding: 3px 17px!important;
        margin: 7px 10px 5px 10px;
      }
    }
    .account{
      &{
        width: 31%;
        margin-left: 10px;
        font-size: 15px;
        margin-top: 12px;
        cursor: pointer;
      }
    }
  }

  .card:hover .tags .add{
    &{
      display: none!important;
    }
  }

  .card .tags:hover .add{
    &{
      display: block!important;
    }
  }

  .tags{
    &{
      width: 40%;
      min-height: 25px;
    }

    .add .dropdown .btn{
      &{
        margin-left: 0;
        font-size: 12px;
      }
    }
    .linked-tags{
      &{
        max-width: 69%;
        margin-right: 15px;
      }
      ul{
        li:first-child{
          &{
            margin-left: 0;
          }
        }
      }
    }
    li{
      &{
        font-size: 12px!important;
        margin-top: 9px!important;;
      }
    }
  }

  .stat {
    margin-top: 5px;
    margin-right: 9px;
  }

  .status{
    &{
      padding-top: 13px;
    }
  }

  .table-head{
    .selector{
      &{
        padding: 0;
        padding-left: 10px;
      }
    }
    .edit-accounts-counter{
      &{
        text-transform: lowercase;
        font-size: 11px;
        margin-left: 5px;
      }
    }
    &, .account{
      font-size: 13px!important;
      text-transform: uppercase;
      font-weight: 500;
    }
    .account{
      &{
        margin:10px;
        margin-left: 10px;
        margin-top: 0!important;
        margin-right: 0;
      }
    }

    .status{
      &{
        padding-top: 0!important;
      }
    }

    .score, .status{
      &{
        cursor: pointer;
      }
      .fa-solid{
        &{
          margin-left: 8px;
        }
      }
    }
  }

  .sc{
    &{
      float: none!important;
      display: inline-flex;
    }
  }

  .deliverability-score{
    &{
      margin: 9px 0px!important;
      font-size: 12px!important;
    }
  }

  .calculating{
    &{
      background: #bbbbbb!important;
    }
  }

  .stat{
    &{
      box-shadow: none!important;
    }
  }

  .score{
    &{
      float: left;
      width: 8%;
    }
  }

  .recipe{
    &{
      float: left;
      width: 10%;
      text-align: center;
    }
  }

  .bot{
    &{
      margin: 0!important;
      margin-top: 5px!important;
      width: 21px;
    }
  }

  a{
    &{
      color: #6f7588;
    }
  }
  .open-link{
    &{
      margin: 13px;
    }
  }
}

.edit-tag-modal{
  &{
    margin-top: 10%;
  }
  .btn.red{
    &{
      color: #fff;
    }
  }
  .imap form .btn{
    &{
      margin-top: 29px;
    }
  }
}

li.tag{
  &.aa{
    background: #dfe9ff!important;
    color: #2770df;
  }
  &.ab{
    background: #ede4fb!important;
    color: #8047de;
  }
  &.ac{
    background: rgb(255, 200, 175)!important;
    color: rgb(122, 46, 11)!important;
  }
  &.ad{
    background: #fff7e3!important;
    color: #ffc740
  }
  &.ae{
    background: rgb(152, 215, 228)!important;
    color: rgb(13, 59, 68);
  }
  &.af{
    background: rgb(227, 215, 255)!important;
    color: rgb(61, 24, 142);
  }
  &.ag{
    background: rgb(251, 211, 224)!important;
    color: rgb(113, 26, 54);
  }
  &.ah{
    background: rgb(73, 134, 231)!important;
    color: rgb(255, 255, 255);
  }
  &.aj{
    background: rgb(45, 162, 187)!important;
    color: rgb(255, 255, 255);
  }
  &.ak{
    background: #a4eef7!important;
    color: #179fb0;
  }
  &.al{
    background: rgb(231, 231, 231)!important;
    color: rgb(70, 70, 70);
  }
  &.am{
    background: rgb(182, 207, 245)!important;
    color: rgb(13, 52, 114);
  }
  &.ao{
    background: rgb(242, 178, 168)!important;
    color: rgb(138, 28, 10);
  }
  &.ai{
    background-color: #acb3b3!important;
    color: #0a0b0b!important;
  }
  &.ar{
    background-color: rgb(246, 145, 179)!important;
    color: rgb(255, 255, 255)!important;
  }
  &.has_notifications_true{
    background-color: #ffdbc2;
    color: #ff7b1d;
  }
}

.spam-result-item{
  .btn-secondary{
    margin-top: 12px;
  }
}

.accounts .card:hover .tags .add{
  &{
    display: block;
  }
}

.tags{
  &{
    float: left;
    max-width: 49%;
    margin-left: 10px;
  }
  .add{
    &{
      display: none;
      float: left;
    }
    .dropdown{
      &{
        float: left;
      }
      .btn{
        background: transparent;
        color: #ccc;
        border: 1px solid;
        padding: 4px 14px !important;
        margin-left: 15px;
        opacity: 0.5;
        color: #6f7588;
        font-size: 13px !important;
        margin-top: 7px;
        margin-left: 10px!important;
      }

      .btn.dropdown-toggle{
        margin-left: 0px!important;
        margin-top: 8px;
      }
    }
    .form form{
      .btn{
        margin: 0;
        margin-left: 14px;
        padding: 5px 15px!important;
        font-size: 16px!important;
        color: #fff;
        background-color: #3d46fb;
        border-color: #3d46fb;
        opacity: 0.8;
      }
    }
    input {
      margin: 0;
      width: 100%;
      width: 76%;
      padding: 6px;
      font-size: 16px;
      color: #18164c;
      background-color: #fff;
      background-clip: padding-box;
      outline: none;
      border: 1px solid #e0e5e9;
      border-radius: 18px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .empty{
    &{
      text-align: center;
    }
    img{
      max-width: 90%;
      margin: 10px 0;
    }
    p{
      margin: 10px 0;
    }
  }

  .dropdown-menu.dropdown-caret-right:after, .dropdown-menu.dropdown-caret-right:before {
    left: unset;
    right: 30px;
  }
   .dropdown-menu {
      width: 350px;
      float: left;
      margin-top: 25px;
      min-width: 12rem;
      padding: 10px;
      margin: 0.85rem 0 0;
      color: #6f7588;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #e0e5e9;
      border-radius: 10px;
      box-shadow: 0 15px 30px 0 #d6d6d6a8;
      animation-name: fadeIn;
      margin-top: -7px !important;
      inset: auto!important;
      top: 22px!important;
    }

      .list{
        &{
          list-style: none;
          float: left;
          width: 100%;
          padding: 0;
          margin-top: 10px;
          max-height: 350px;
          overflow: auto;
        }
        li{
          &{
            float: left;
            margin: 7px 15px;
            margin-right: 0;
            border-radius: 18px;
            font-size: 13px;
            font-weight: 500;
            float: left;
            cursor: pointer;
          }
          .tag-name{
            &{
              padding: 5px 5px 5px 15px;
              float: left;
            }
          }
          .open-edit-tag-modal{
            &{
              padding-left: 5px;
              padding-right: 3px;
              width: 20px;
              margin-top: 5px;
              float: left;
            }
          }
        }
      }
  }

  &:hover{
    ::-webkit-scrollbar {
      width: 3px;
      display: block;
    }
  }
  ::-webkit-scrollbar {
    height: 3px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .linked-tags{
    &{
      height: 46px;
      overflow-x: hidden;
      overflow-y: auto;
      float: left;
      margin-right: 10px;
      max-width: 415px;
    }
    .remove{
      &{
        cursor: pointer;
        margin-left: 7px;
        margin-top: 0px;
      }
    }
    .list{
      &{
        list-style: none;
        margin: 0;
        padding-left: 0;
      }
        li{
          &{
            float: left;
            margin: 8px 13px;
            margin-right: 0;
            padding: 5px 12px;
            border-radius: 18px;
            font-size: 13px;
            font-weight: 500;
            float: left;
            display: flex;
          }
        }
    }
  }
}

.criteria{
  .loader{
    float: left;
    margin-top: 13px;
    margin-left: 15px;
  }
}

.tags-list{
  &{
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 10px;

  }
    li{
      &{
        float: left;
        margin: 7px 15px;
        margin-right: 0;
        padding: 5px 12px;
        border-radius: 18px;
        font-size: 13px;
        font-weight: 500;
        float: left;
      }
      &:first-child{
        &{
          margin-left: 0;
        }
      }
      .remove{
        cursor: pointer;
        margin-left: 9px;
        margin-top: 0px;
        float: right;
      }
    }

}

.grid-view{
  &{
    width: 2%!important;
   float: right!important;
  }
  .fa-list{
    &{
      cursor: pointer;
    }
  }
}

.w-compressed-v.search{
  input{
    width: 54%;
  }
}

.search{
  &{
    float: left;
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
  }
  form{
    &{
      float: left;
      display: contents;
    }
  }
  input{
    width: 58%;
    margin: 0;
    padding: 18px;
    margin-top: 2px;
    font-size: 15px;
    border: none;
    border-radius: 10px;
    color: #18164c;
    padding-left: 58px;
    border: 1px solid #e0e5e9;
    box-shadow: 0 2px 4px 0 rgb(1 1 2 / 5%);
  }
  input:focus-visible{
    outline: none;
  }
  .fa-search{
    position: absolute;
    font-size: 21px;
    margin: 21px 22px;
    opacity: 0.4;
  }

  .tags-filter{
    &{
      margin-right: 1%;
    }
  }

.search-actions-btn{
  &{
    width: 5%!important;
    margin-left: 1%!important;
  }
  &.dropdown-date-filter{
    margin-top: 7px!important;
  }
}

  .filters{
    &{
      float: right;
      width: 16%;
    }

    .fa-sort-amount-down, .fa-filter, .fa-plus{
      margin-left: 0px;
      margin-right: 5px;
    }

    .dropdown-date-filter{
      &{
        width: 100%;
      }
    }
    .dropdown-menu{
      &{
        width: 100%;
      }
    }

    .last-days-filter{
      margin-right: 0;
    }

    .dropdown-toggle.btn{
      padding: 18px 5px!important;
      margin-top: -5px;
      width: 100%;
      border-radius: 10px!important;
      font-size: 15px!important;
    }
  }
}

.empty-results{
  img{
    max-width: 500px;
  }
  h2{
    width: 100%;
  }
}

.status{
  .btn{
    &{
      background: #0000007d;
    }
    &:hover{
      background: #000000b8;
      color: #fff;
    }
  }
}

.alert.warning .txt a.btn{
  &{
    text-decoration: none;
    color: #fff;
  }
}

.pulse{
  animation: pulse 2s infinite;
}

@keyframes pulse {
0% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
}

70% {
  transform: scale(1);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
}

100% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
}

.alert.bounce{
  &{
    background: #ff7b1d!important;
  }
  a{
    &{
      color: #fff;
      font-weight: 500;
      text-decoration: none;
    }
  }
}

.alert.recipe{
  &{
    background-color: #11103b !important;
    color: #fff!important;
  }
  .btn{
    &{
      background: #fff !important;
      color: #11113b!important;
      opacity: 0.98!important;
    }
    &:hover{
      opacity: 1!important;
    }
  }
  .txt{
    &{
      padding-left: 0!important;
    }
  }
}

.add-tag-modal{
  .tags{
    &{
      max-width: 100%;
      width: 100%;
      text-align: center;
      margin: 10px 0 40px;
      float: left;
    }
    .add{
      display: block;
      float: none;
    }

    input{
      &{
        width: 67%;
      }
    }

    .tag-name {
      padding: 5px 10px 5px 10px!important;
    }

    .dropdown{
      &{
        float: none;
      }
      .btn.dropdown-toggle{
        &{
          padding: 10px 18px!important;
          opacity: 1;
          font-size: 15px!important;
          background: #ccc;
    border: 1px solid #e0e5e9;
    color: #6f7588;
    background-color: #f4f6f9;
        }
      }
    }
  }
  .linked-tags{
    &{
      max-width: 100%;
      overflow: auto;
      height: auto;
      margin-top: 33px;
    }
  }
}

.alert.recipe.status{
  .txt{
    a{
      color: #fff;
      text-decoration: none;
    }
    a:hover{
      text-decoration: underline;
    }
  }
}

.modal-onboarding-copilot{
  ul{
    li{
      &{
        list-style: none;
      }
    }
  }
}

.modal-onboarding-score{
  ul{
    li{
      list-style: none;
      text-align: left;
      margin: 0 0 12px 0;
      float: left;
    }
    .one-line{
      margin: 0 0 3px 0;
    }
  }
  h1{
    &{
      margin-bottom: 0;
    }
  }
  h2{
    &{
      font-size: 20px;
      text-align: left;
      margin-bottom: 20px;
    }
  }
  span{
    &{

    }
  }

  .ico{
    &{
      height: 33px;
      float: left;
      margin-right: 8px;
      margin-top: 1px;
    }
  }

  .footer{
    &{
      margin-top: 10px;
    }
  }

  .btn{
    &{
      margin-right: 20px;
    }
  }
}

.fix-issue-modal{
  .modal-content, .modal-dialog{
    width: 1100px!important;
    max-width: 1100px!important;
  }
  .account{
    padding-top: 20px;
  }
  a{
    text-decoration: none!important;
  }
  table .btn{
    &{
      font-size: 15px !important;
      font-weight: 500 !important;
      padding: 6px 20px !important;
      margin: 7px 0;
    }
  }

  .alert{
    &{
      margin-bottom: 35px;
    }
    span{
      max-width: 88%;
      display: inline-block;
    }
  }
}
.alert.status{
  &{
    background: #ff1d51;
    font-size: 15px;
    color: #fff;
  }
  .txt{
    &{
      padding-left: 30px;
    }
    &.grouped{
      padding-left: 0px;
    }
    p{
      margin: 0;
    }
    a{
      text-decoration: underline;
      color: #301c6b;
    }
  }
  &.beta, &.infos{
    background: #fff;
    color: #301c6b;
  }
  &.green{
    background: #01d34f;
    color: #ffffff;
  }
  .btn{
    font-size: 15px!important;
    margin-top: -9px;
    color: #fff;
  }
}

.btn.red{
  background-color: #ff1d51!important;
  border: 1px solid #ff1d51!important;
}

.card{
  .title{
    &{
      padding-bottom: 16px;
      margin-bottom: 0;
      background-color: transparent;
      border-bottom: 1px solid #e0e5e9;
    }
    h2{
      font-size: 18px;
      line-height: 18px;
    }
  }
}

.margin-top{
  &{
    margin-top: 10px;
  }
}


.btn-secondary{
  &{
    background: #ccc;
    border: 1px solid #e0e5e9;
    color: #6f7588;
    background-color: #f4f6f9;
    box-shadow: 0 2px 4px 0 rgba(1,1,2,.05);
  }
}

.accounts{
  &{
    margin-top: 30px;
  }
  .card{
    &{
      margin-bottom: 20px;
    }
  }
  .btn-secondary{
    &{
    }
  }
  .account{
    margin-top: 10px;
    font-size: 15px;
    float:left;
  }
}

.home{
  .deliverability-score{
    &{
      float: left;
          color: #fff;
          margin: 8px 13px;
          margin-right: 0;
          padding: 5px 12px;
          border-radius: 18px;
          font-size: 13px;
          font-weight: 500;
    }
    &.bad{
      background: #ff1e51;
    }
    &.average{
      background: #ff9b1e;
    }
    &.stable{
      background: #bebebe;
    }
    &.good, &.great{
      background: #00d463;
    }
  }
  .title{
    .btn{
      color: #fff;
      background-color: #3d46fb;
      border-color: #3d46fb;
      opacity: 0.8;
      margin-top: 15px;
    }
  }
  .card{
    .bot{
      &{
        float: left;
        font-size: 25px;
        margin: 3px;
        margin-left: 15px;
        margin-right: 12px;
      }
      .fa-circle-exclamation{
        color: #ff7b1d!important;
        font-size: 17px;
      }
    }
    .status{
      &{
        float:left;
        margin: 17px 12px 0 0px;
      }
      .stat{
        &{
          width: 10px;
          height: 10px;
          float: left;
          border-radius: 50%;
          cursor: pointer;
        }
        &.alive{
          box-shadow: 0px 0px 3px 0px #1ac797;
          background: #1ac797;
        }
        &.ko{
          box-shadow: 0px 0px 3px 1px #ff1d51;
          background: #ff1d51;
        }
        &.kok{
          box-shadow: 0px 0px 3px 1px #ff701d;
          background: #ff7b1d;
        }
        &.suspended{
          box-shadow: 0px 0px 3px 1px #ff701d;
          background: 0px 0px 3px 1px #ff701d;
        }
      }
    }
  }
}

.MuiTooltip-tooltip{
  &{
    font-size: 16px!important;
    background-color: #11103b!important;
    padding: 10px!important;
  }
}

.alert{
  .close{
    margin-right: 15px;
  }
}

.btn.primary{
  color: #fff;
  background-color: #3d46fb;
  border-color: #3d46fb;
}
.add-account-modal{
  .modal-dialog{
    &{
      max-width: 730px;
    }
  }
  .modal-content{
    &{
      width: 730px;
    }
    .modal-body{
      &{
        padding: 50px;
      }
      h1{
        text-align: center;
        margin-top: 0;
        margin-bottom: 40px;
      }
      .line{
        &{
          padding: 13px;
          font-size: 17px;
          border-bottom: 1px solid #0000001f;
        }
        b{
          font-weight: 500;
        }
        &.no-padding-left {
          padding-left: 0 !important;
        }
      }
      .txt{
        font-size: 16px;
      }
      .footer{
        &{
          width: 100%;
          text-align: center;
          margin-top: 40px;
        }
        button{
          margin: 0 10px;
        }
      }
    }
  }
}

.margin-left-t{
  &{
    margin-left: 10px;
  }
}

.modal-onboarding-init.reputation-modal.settings-modal.recpt{
  .modal-dialog{
    &{
      max-width: 855px;
    }
    .giphy-embed{
      &{
        border-radius: 8px;
      }
    }
    p{
      &{
        margin: 0 0 20px;
      }
    }
    .close-red{
      &{
        color: red;
        background: transparent;
        box-shadow: none;
        border: none;
      }
    }
  }
}

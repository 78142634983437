.sidebar{
  &{}
}


.background-wth-one{
  &{
    background: #fff;
    width: 43%;
    position: absolute;
    height: 100%;
  }
}


.pricing-pay-v2{
  .col-md-8{
    h1{
      &{
        margin-left: 28px;
      }
    }
  }
  .app.container{
    &{
      padding: 0;
      height: 100%;
      position: relative;
    }
    .fieldCont{
      label{
        &{
          color: #18164c;
        }
      }
    }
    .checkout{
      .card{
        &{
          box-shadow: none;
        }
      }
      .col-md-8{
        .card{
          &{
            padding-right: 65px;
          }
        }
      }
      .col-md-8, .col-md-4.sticky-payment{
        &{
          padding-top: 12vh;
        }
      }
      .sticky-payment{
        &{
          top: 0;
        }
        h1{
              padding-bottom: 23px;
        }
        .card{
          &{
            background: #f4f8fc;
            margin-top: 0;
            padding-top: 0;
            padding-left: 65px;
          }
        }
        .line, .total{
          &{
            text-align: left;
          }
        }

        .btn.purple-btn{
          &{
            margin-top: 25px;
            background-color: #28b970 !important;
            width: 100%;
            border-radius: 4px !important;
          }
        }
        .btn.red{
          &{
            margin-top: 25px;
            width: 100%;
            border-radius: 4px !important;
          }
        }
        .secure-payment{
          &{
            text-align: left;
          }
        }
      }
      .col-md-8{
        &{
          background: #fff;
          height: 100vh;
          /* height: 1400px; */
          /* right: 0; */
          /* position: absolute; */
          /* top: 51px; */
          padding-right: 0;
        }
      }
    }
  }
}

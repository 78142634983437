.checkount-main-items{
  .edit-btn-selected{
    &, &:hover, &:active{
      background: #ccc!important;
      background-color: #f4f6f9!important;
      color: #6f7588!important;
    }
  }
  .discount{
    &{
    color: #00c217;
    font-size: 13px;
    background: #e4fae5;
    padding: 3px 5px;
    border-radius: 5px;
    }
  }
  &{
    padding: 0;
    margin-top: 0;
  }
  .col-md-6{
    &{
      padding: 2px 10px;
    }
  }
  .col-md-3{
    &{
      padding: 6px;
      padding-top: 8px;
    }
  }
  li{
    &{
      list-style: none;
      border: 1px solid #ccc;
      width: 100%;
      border-radius: 5px;
      padding: 10px;
      float: left;
    }
    &:first-child{
      &{
        margin-bottom: 20px;
      }
    }
    .btn{
      &{
        border: none;
        margin-top: 2px;
      }
    }
    h3{
      &{
        margin: 0;
        padding: 5px 0;
        font-size: 17px;
        color: #11103b;
      }
    }
  }
}

.checkout-seats-modal.checkout-spamchecker-modal{
  .modal-body{

    .MuiSwitch-root{
      &{
        margin-top: 4px;
      }
    }

    .discount{
      &{
        margin-left: 5px;
      }
    }

    h1{
      &{
        padding-bottom: 0;
      }
    }
    .plan .col-md-12.no-padding-left{
      &{
        padding-left: 5px;
      }
    }

    .seats-checkout{
      &{
        margin-bottom: 15px;
      }
    }
  }
}

.checkout-seats-modal-v2{
  &.checkout-seats-modal{
    .modal-body{

      h1{
        &{
          padding-bottom: 0;
        }
      }
      .plan .col-md-12.no-padding-left{
        &{
          padding-left: 5px;
        }
      }
    }
  }
  .MuiSlider-sizeMedium{
    &{
      width: 97%;
    }
  }
}

.checkoutv3-back-btn{
  &{
    float: left;
    color: rgba(19, 20, 49, 0.4);
    margin-top: -21px;
    margin-left: 30px;
  }
}

.checkout-seats-modal,.billing-ananas{
  .bln-color{
    &{
      color: #11103b;
    }
  }
}

.billing-ananas{

  .one-time-summary{
    &{
      padding-left: 39px;
    }
  }

  .card.checkout.imap{
    &{
      padding: 15px 28px 28px;
    }
  }

  .plan-title{
    margin-bottom: 24px;
    margin-top: 12px;
  }
  .billing-summary{
    &{
      padding: 20px 15px 0 11px;
    }
    .bold{
      &{
        font-weight: 500;
      }
    }

    .btn-primary.btn{
      &{
        margin-top: 5px;
        border: none;
      }
    }

    .big{
      &{
        font-size: 17px;
      }
    }
  }
}



.checkout-seats-modal-v2{
  span.discount{
    &{
      margin-left: 10px;
    }
  }

  .select-billing-period {
    &{
      font-size: 13px;
    }
  }

  .seats-checkout .plans .mailboxes-increase input {
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .plans .plan.no-border{
    &{
      border: none;
    }
  }
}


.checkout-spamchecker-modal{
  h1.text-left{
    &{
      padding-left: 20px;
    }
  }
  .modal-content, .modal-dialog{
    &{
      max-width: 900px!important;
    }
  }

  .seats-checkout .select-billing-period{
    &{
      font-size: 13px;
    }
  }


  .seats-checkout{
    &{
      padding: 0px;
      margin-bottom: 20px;
    }

    .select-billing-period{
      &{
        padding: 5px 24px;
        margin-top: 0;
      }
    }
  }
}


.checkout-seats-modal{

  .footer button {
    margin: 0 10px;
  }

  .footer{
    &{
      margin-bottom: 20px;
    }
  }

  .spamcheck-checkout{
    &{

    }

    .features-top-maging{
      &{
        margin-top: 39px;
      }
    }

    .btn-primary.green-bck.btn{
      &{
        border: none;
      }
    }

    .select-plan-btn{
      &{
        padding: 5px 0px 15px 0px;
      }
    }

    h3{
      &{
        color: #11103b;
      }
    }

    span.price-style{
      &{
        color: #11103b;
        font-size: 14px;
        font-weight: 500;
      }
      &.big{
        font-size: 31px;
      }
      &.currency{
        &{
          position: relative;
          top: -11px;
          margin-right: 4px;
        }
      }
    }

    .plans{
      .col-md-4.plan{
        &{
          width: 31.32%;
        }
      }
    }
  }

  h1.text-left{
    &{
      padding: 0px 5px 15px 25px;
    }
  }
  .modal-content, .modal-dialog{
    &{
      max-width: 800px;
    }
  }

  .seats-checkout .plans .plan{
    &{
      width: 98%;
      margin-left: 1%;
    }
  }

  .seats-checkout .plans{
    .select-billing-period{
      &{
        padding: 0;
        float:left;
        width: auto;
      }
    }

    p.desc-txt{
      &{
        font-size: 14px;
        text-align: left;
        margin: 10px 4px;
      }
    }
    .pricing{
      .all-sum{
        &{
          font-size: 12px;
          opacity: 0.7;
        }
      }
    }
    .mailboxes-increase, .pricing{
      &{
        width: 48%;
        border: 1px solid #ccc;
        padding: 4px;
        border-radius: 5px;
        margin-top: 15px;
      }
      .all-sum{
        .money{
          &{
            top: -5px;
          }
        }
      }
    }
    .pricing{
      .col-md-7{
        &{
          margin-top: 11px;
        }
      }
    }
    .mailboxes-increase{
      &{
        margin-right: 2%;
        margin-left: 1%;
        font-weight: 500;
        height: 81px;
        padding: 16px 11px;
      }
      input{
        &{
          width: 30%!important;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .checkout-seats-modal .spamcheck-checkout .plans .col-md-4.plan {
      width: 100%;
      margin: 20px 0;
  }
}

@media (max-width: 650px) {
  .checkount-main-items .discount  {
    position: absolute;
  }
  .billing-ananas .billing-summary .btn-primary.btn {
    margin-top: 35px!important;
  }
}

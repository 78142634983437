
.emailvalidation{


  .templates .spam-result-item:nth-child(2) {
    margin-top: 30px!important;
  }


  .progress-nb{
    &{
      font-size: 15px;
      margin-top: -11px;
    }
  }

  .emailvalidation-tasks .timeago{
    &{
      margin-top: 3px;
    }
  }


  .card.upload-block{
    .col-md-6{
      h2{
        &{
          font-size: 25px;
              margin-top: 0;
        }
      }
    }
  }

  .emailvalidation-alert-modal{
    .modal-body{
      &{
        padding-bottom: 25px;
      }
    }
  }


  .errored{
    &{
      background: #ffc2c2!important;
      color: #d83b22!important;
      background: #ccc;
      font-weight: 500;
      color: #636363;
      font-size: 14px;
      text-transform: capitalize;
      border-radius: 17px;
      padding: 4px 15px;
      margin-top: 15px;
      cursor: pointer;
      float: left;
    }
  }

  .upload-block{
    &{
      position: relative;
      padding: 25px;
      margin-top: 30px;
    }

    .fa-cloud-upload{
      &{
        font-size: 25px;
        margin-bottom: 10px;
      }
    }
  }

  .file-upload-zone{
    &{
      display: flex;
      align-items: center;
      justify-content: center;
      border-width: 2px;
      border-radius: 1rem;
      border-style: dashed;
      border-color: #cdcbe1;
      background-color: #f9f8fc;
      height: 200px;
      margin-top: 0;
      cursor: pointer;
    }
  }

  .drag-file-element{
    &{
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }

  .submit-btn{
    &{
      margin-top: 18px;
    }
    .btn{
      &{
        border: none;
      }
    }
  }

  textarea{
    &{
      width: 100%;
      height: 200px;
      margin-top: 11px;
      border-radius: 6px;
      border-radius: 1rem;
      border-color: #cdcbe1;
      padding: 10px;
    }
  }

  .col-md-6{
    &{
      padding: 0px 30px;
    }
  }

  .or{
    &{
      float: left;
      background: #fff;
      height: 27px;
      width: 33px;
      font-size: 14px;
      position: absolute;
      margin-left: 46.8%;
      margin-top: 13%;
      padding-top: 3px;
    }
  }

  .first-col-upload{
    &{
      border-right: 2px dashed #cccccc69;
      padding-left: 0;
      padding-right: 25px;
    }
    .choose-file{
      &{
        float: none;
        width: auto;
      }
    }
  }

  .last-col-upload{
    &{
      padding-right: 0;
      padding-left: 25px;
    }
    textarea{
      &{
        margin-top: 0;
      }
    }
  }

  .first-col-upload, .last-col-upload{
    h2{
      margin-top: 10px;
    }
    span{
      &{
        padding-bottom: 20px;
        float: left;
        width: 100%;
      }
    }
  }

  .separator{
    &{
      background: #fff;
      width: 15px;
      height: 15px;
      position: absolute;
      margin-top: 11%;
      margin-left: 47.3%;
      font-size: 19px;
    }
  }

  .choose-file{
    &{
      cursor: pointer;
      color: #686afc;
    }
  }
}


.emailvalidation-tasks{
  .emails-nb{
    &{
      padding-top: 6px;
      display: block;
      font-size: 17px;
    }
  }
  .col-md-2{
    .btn.grey{
      &{
        margin-top: 11px;
      }
    }
  }

  .progress-bar-valid{
    &{
      background-color: #fd443a !important;
    }
  }
  .progress-bar-green{
    &{
      background-color: #22ca48 !important;
    }
  }
  .progress{
    &{
      height: 10px;
      margin-top: 14px;
    }
    .progress-bar{
      &{
        width: 10%;
        background-color: #11113b;
        box-shadow: none;
      }
    }
  }
}

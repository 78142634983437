.projects-selector{
  &{
    width: 350px;
    margin: 0 auto;
  }
  .projectLogo{
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 10px;
  }
  ul{
    &{
      padding: 0;
      margin: 0;
      list-style: none;
    }
    li{
      background: #fff;
      box-shadow: 0 0 1px rgba(67,90,111,.415), 0 2px 4px -2px rgba(67,90,111,.301);
      border-radius: 4px;
      padding: 12px;
      margin-bottom: 10px;
      margin: 35px 0;
      display: flex;
      flex-grow: 1;
      position: relative;
      cursor: pointer;
      text-decoration: none;
      color: #47b881;
      transition-property: color,border;
      transition-duration: 225ms;
      transition-timing-function: cubic-bezier(.4,0,.2,1);
      -webkit-transition: box-shadow .15s ease,-webkit-transform .15s ease;
    transition: box-shadow .15s ease,-webkit-transform .15s ease;
    transition: box-shadow .15s ease,transform .15s ease;
    transition: box-shadow .15s ease,transform .15s ease,-webkit-transform .15s ease;
    }
    a{
      text-decoration: none;
      color: #212529;
    }
    li:hover{
      -webkit-transform: translateX(5px);
      -moz-transform: translateX(5px);
      -ms-transform: translateX(5px);
      -o-transform: translateX(5px);
      transform: translateX(5px);
    }
    .name{
      color: #212529;
      font-weight: 500;
      font-size: 17px;
      float: left;
      margin: 4px 0;
      margin-top: 0;
    }
    .desc{
      color: #212529;
      opacity: 0.7;
      font-size: 13px;
      float: left;
      min-width: 65%;
    }
  }
  img{
    width: 60px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
}
.down{
  width: 150px;
  margin-right: 50px;
}

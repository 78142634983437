.app .head-page {
  border-bottom: 1px solid #d2d2d2;
  padding: 30px 0; }

.app .head-page .dropdown {
  padding: 22px; }

.app .head-page .dropdown button {
  background: transparent;
  border: none;
  float: right;
  color: #000; }

.app .head-page .dropdown button img {
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.27); }

footer {
  margin-bottom: 40px; }

footer img {
  width: 210px; }

footer ul {
  list-style: none; }

footer ul li {
  float: left;
  padding: 12px; }

footer ul li a {
  color: #757f8b !important; }

footer ul li a:hover {
  text-decoration: none; }

.modal-backdrop {
  width: 100%;
  height: 100%; }

.margin-right {
  margin-right: 15px; }

.break-word{
  word-break: break-all;
}

.modal-content .headers{
      font-size: 13px;
}

.headers table tr:first-child td{
  border-top: 0!important;
}

.seedlist-btn{
  &{
    border-radius: 8px !important;
    margin: 5px 0 !important;
  }
}

.search-spamchecker{
  &{
    padding: 15px 0;
    width: 100%;
  }
  input{
    &{
      width: 100%;
    }
  }
}


@media only screen and (max-width: 470px) {
  .container.home.spamtest-results{
    &{
      padding-right: 15px;
    }
    .accounts.templates{
      &{
        padding-left: 0;
      }
    }
  }
  .spam-result-item{
    .account{
      &{
        width: 70%;
        float: left;
      }
    }

    .score {
      margin-top:17px;
    }

    .last-test-result{
      &{
        float: left;
        margin-top: 10px;
      }
    }
  }

  .plans .plan{
    width: 100%!important;
    margin-top: 5px!important;
    margin-bottom: 5px!important;
  }


  .emails-list{
    &{
      max-width: 100%!important;
    }

    .list{
      &{
        max-width: 100%!important;
      }
    }
  }

  .launch-spamtest-tuto-new{

    .email-code{
      width: 100%;
    }

    .code{
      width: 100%;
    }
  }

}

.first-automated-test{
  img{
    width: 55%;
    margin: 25px 0;
  }
}

.automated-test-nb{
  &{
    cursor: pointer;
    border-left: 1px solid #e2e1e1;
  }
  h2{
    &{
      font-size: 95px!important;
      margin: 51px 0;
    }
  }
  .btn{
    margin: 14px 0;
  }
}

.spamcheck-nav-min{
  &{
    float: left;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    font-weight: 500;
  }
  li{
    list-style: none;
    float: left;
    cursor: pointer;
    padding: 5px 10px 8px 5px;
  }
  li.selected{
    color: #11103b;
    border-bottom: 2px solid;
    margin-bottom: -1px;
  }
}

.automated-test-padding-top{
  &{
    padding-top: 16px;
  }
}

.spamtest-auto{
  .title{
    .btn-secondary{
      &{
        background: #ccc;
        border: 1px solid #e0e5e9;
        color: #6f7588;
        background-color: #f4f6f9;
        box-shadow: 0 2px 4px 0 rgba(1, 1, 2, 0.05);
      }
    }
    .btn{
      &{
        margin-left: 15px;
      }
    }
  }
}



.spam-result-item .no-results{
  &{
    text-align: center;
    float: none;
    margin: 40px auto 0;
    max-width: 70%;
  }
  h3{
    &{
      text-align: center;
    }
  }
  img{
    margin: 58px auto 20px;
    max-width: 200px;
    margin-top: 10px;
  }
}

.th-text-center{
  text-align: center!important;
}

.alert-danger{
  &{
    background: #ff1d51;
    color: #fff;
  }
}

.no-margin-bottom{
  &{
    margin-bottom: 0;
  }
}

.alert-orange{
  &{
    background: #ff9d54;
    color: #fff;
    padding: 10px;
  }
  a{
    color: #fff;
    text-decoration: underline;
  }
}
.nextstep-number{
  &{
    background: #fe1d51;
    border-radius: 50%;
    color: #fff;
    float: left;
    font-size: 18px;
    font-weight: 500;
    height: 36px;
    margin-right: 14px;
    margin-top: -4px;
    padding: 5px;
    text-align: center;
    width: 36px;
  }
}

.share-txt {
    float: right;
    padding: 38px 10px 5px;
    font-weight: 500;
    color: #11103b;
}

.share-link {
  &{
    background-color: #fff!important;
    border: 1px solid #ccc;
    border-radius: 8px;
    color: #11103b!important;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    font-weight: 500;
    margin: 25px 0 0;
    float: right;
  }
  .code, .copy{
    padding: 12px;
  }
  .copy{
    background: #f8f9fb;
    border-bottom-right-radius: 8px;
    border-left: 1px solid #ccc;
    border-top-right-radius: 8px;
    color: #10113b;
    float: left;
    font-size: 14px;
    margin-left: 0;
    padding: 10px 16px;
  }
}

.spamtest-results{
  .warning.card{
    &{
      padding: 0px 0px!important;
      margin-right: 0!important;
      margin-bottom: 25px!important;
    }
    .txt{
      &{
        padding: 20px;
      }
    }
  }
}

.spamtest-results{
  .warning.recipe.card{
    &{
      padding-left: 20px!important;
    }
  }
}

.format-selector{
  &{
    list-style: none;
  }
  li{
    &{
      float: left;
      margin-right: 10px;
      font-size: 12px!important;
      padding: 8px 20px !important;
      margin-top: -8px;
    }
    &.selected{
      background-color: #3d46fb;
      border-color: #3d46fb;
      color: #fff;
    }
  }
}

.selector{
  .download-emails-list{
    &{
      margin-left: 20px;
      color: #6f7588!important;
    }
  }

  .seedlist{
    &{
      display: inline;
      margin-left: 100px;
    }
  }
}

.template-accounts-selector{
  &{
    padding: 0;
    margin: 10px 0;
    width: 100%;
  }
  li{
    &{
      list-style: none;
      float: left;
      padding: 10px 19px;
      color: #6f7588;
      background-color: #f4f6f9;
      border-radius: 29px;
      margin: 5px;
      font-weight: 500;
      cursor: pointer;
    }
    &:hover{
      color: #6f7588;
      background-color: #dbe2ec;
    }
    &.selected{
      color: #fff;
      background-color: #3d46fb;
    }
  }
}

.inbox-type-label{
  &{
    background: #e9e9e9;
    padding: 5px 12px;
    border-radius: 10px;
    margin-top: 5px;
    float: left;
    margin-left: 15px;
    color: #5c98df !important;
    background-color: #e2edf9 !important;
    border-color: #5c98df !important;
    border: 1px solid;
  }
  &.perso{
    &{
      color: #da9e53 !important;
      background-color: #fcebcc !important;
      border-color: #da9e53 !important;
    }
  }
}

.dropdown-inbox-type-selector{
  &{
    float:left;
    margin-right: 8px;
  }
}

p.insight-content{
  &{
    height: 115px;
    padding: 15px 0px;
  }
}

.spamtests-loading{
  &{
    margin-top: 25px;
    margin-left: 15px;
  }
  &.top-margin{
    margin-top: 33px;
  }
}
.templates{
  .empty{
    img{
      width: 50%;
    }
  }
}


.spamtest-results{
  &{
    padding-top: 15px !important;
        padding-right: 0;
  }
}


.ct-spamtest{
  &{
    padding-right: 10px;
  }
}


.ar-nav{
  &{
    color: #6f7588;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
    padding: 0px 25px;
    padding-left: 0;
  }
  a{
    &{
      margin: 0 5px;
    }
    &:first-child{
      margin-left: 15px;
    }
  }
}

.top-margin{
  margin-top: 50px;
}

.request-action{
  &{
    font-size: 15px;
  }
  .col-md-10{
    padding-top: 16px;
  }
  img{
    width: 65px;
  }
  .start-email{
    margin-left: 10px;
  }
  .txt{
    float: left;
    margin-top: 5px;
  }
  .btn{
    margin-top: -9px;
  }
  .loading{
    .loader{
      margin-right: 15px;
      margin-top: -9px;
    }
    &.txt{
      width: 100%;
    }
  }
}

@keyframes pulse {
	0% {
		opacity: 0.1;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0.1;
	}
}

.start-email{
  &{
    background: #00000012;
    display: initial;
    padding: 5px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-weight: 500;
  }
}

.templates{
  .score-change{
    .increase{
      &{
        color: #1ac797;
        background: transparent!important;
      }
      &.bad{
        color: #ff1d51;
      }
      &.grey{
        color: #6f7588;
      }
    }
  }
  .spam-result-item{
    &:nth-child(2){
      margin-top: 20px!important;
    }
  }
  .increase{
    &{
      border-radius: 17px!important;
      margin-top: 18px!important;
    }
  }
  .dropdown-menu-a{
    &{
      padding: 15px;
      width: 325px;
      border-radius: 11px;
      border: 1px solid #e0e5e9;
      border-radius: 10px;
      box-shadow: 0 15px 30px 0 #d6d6d6a8;
      margin-left: -51px!important;
      margin-top: 10px!important;
    }
    a{
      width: 100%;
     float: left;
    }
    a:hover{
      text-decoration: none;
    }
    &:before, &:after {
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      content: "";
      display: inline-block;
      left: 30px;
      position: absolute;
      border-bottom: 14px solid #e0e5e9;
      top: -15px;
      margin-right: 131px;
    }
    &:before, &:after{
      left: unset;
      right: 30px;
    }
    &:after{
      border-bottom: 14px solid #fff;
      top: -14px;
    }
    ul{
      &{
        list-style: none;
        padding: 0;
      }
      li{
        &{
          color: #6f7588;
          font-weight: 500;
          padding: 13px;
          font-size: 16px;
          margin: 8px 0;
          cursor: pointer;
          border-radius: 10px;
        }
        a{
          color: #6f7588;
          font-weight: 500;
          font-size: 16px;
        }
        &:hover{
          background-color: #f4f6f9;
        }
        i{
          margin-right: 9px;
        }
      }
    }
  }
  .tit{
    &{
      color: #18164c;
      padding-top: 6px;
      cursor: pointer;
    }
    .subject{
      color: #18164c;
      font-weight: 500;
      width: 100%;
      float: left;
    }
    p{
      font-size: 15px;
      opacity: 0.6;
      margin-top: 6px;
      float: left;
    }

    &:hover{
      .preview-hide{
        display: block;
      }
      .test-from-email{
        display: none;
      }
    }

    .preview-hide{
      display: none;
    }
  }
  .template-name{
    &{
      margin-top: 4px;
    }
  }
  .running-on{
    &{
      padding: 11px 5px;
    }
  }
  .accounts-selector{
    &{
      cursor: pointer;
    }
  }
  .score{
    &{
      background: #ff1d51;
      margin-top: 6px;
      display: inline-block;
      padding: 4px 8px;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
    }
    &.running, &.average{
      background: #f7bf23;
    }
    &.good{
      background: #1ac797;
    }
  }
  .timeago{
    &{
      margin-top: 10px;
      opacity: 0.8;
    }
  }

  .actions-dropdown{
    &{
      background: none;
      color: #6f7588;
      border: none;
      opacity: none;
      font-size: 16px!important;
      margin-top: 12px;
    }
  }
  .status{
    &{
      margin: 28px 12px 0 0px!important;
    }
    .running{
      box-shadow: 0px 0px 3px 0px #f7bf23;
      background: #f7bf23;
      animation: pulse 2s infinite;
    }
  }
}
.ico-btn{
  &{
    background: none;
    border: none;
    font-size: 26px;
    color: #6f7588;
    opacity: 1;
    margin-right: 20px;
  }
  &:hover{
    opacity: 1;
  }
}

.spam-results-modal{
  .empty{
    img{
      max-width: 400px;
      margin: 40px 0;
    }
  }
  .modal-content, .modal-dialog{
    &{
    max-width: 950px;
    }
  }
  .txt{
    &{
      padding: 20px 0;
    }
  }
  tr{
    td{
      border-top: 1px solid #0000000d !important;
      padding: 14px!important;
    }
    &.INBOX{
      background: #edfffa;
    }
  }
  .badge{
    &{
      background: #ccc;
      padding: 8px 9px;
      font-weight: 500;
      color: #636363;
      font-size: 15px;
      text-transform: capitalize;
      border-radius: 17px;
      padding: 10px 15px;
      margin-top: 4px;
      cursor: pointer;
    }
    &.INBOX{
      background: #bbf7de;
      color: #008c53;
    }
    &.PERSONAL, &.UPDATES, &.PROMOTION, &.categorie{
      background: #ffe8c2!important;
      color: #ee7f0c!important;
    }
    &.SPAM{
      background: #ffc2c2!important;
      color: #d83b22!important;
    }
  }
  .avatar{
    width: 45px;
    margin-right: 10px;
    border-radius: 4px;
  }
  .providerbadge{
    &{
      padding: 5px 9px;
      border-radius: 10px;
      text-transform: capitalize;
      margin-top: 5px;
      float: left;
      color: #11103b;
      border: 1px solid #11103b;
    }
    &.outlook{
      color: #0067b8;
      border: 1px solid #0067b8;
    }
    &.yahoo{
      color: #9302c1;
      border: 1px solid #9302c1;
    }
  }

  .running{
    margin-top: 33px;
    font-size: 12px;
    margin-left: 0px;
    margin-right: 10px;
  }
}

.spamtest-free{
  &{
    margin-top: 0;
    margin-bottom: 50px;
  }
  img{
    width: 90px;
  }
  .col-md-10{
    &, .txt{
      margin-top: 0;
    }

    &{
      padding-top: 10px;
    }

  }
}

.inbox-badge-color{
  background: #bbf7de!important;
  color: #008c53!important;
}
.categorie-badge-color{
  background: #ffe8c2!important;
  color: #ee7f0c!important;
}
.missing-badge-color{
    background: #ece4fa!important;
    color: #5d22d2!important;
}
.spam-badge-color{
  background: #ffdae2!important;
  color: #ff1d51!important;
}


.spam-result-item .last-test-result{
  &{
    width: 14%;
    margin-left: 0;
    padding-left: 0;
  }
}

.spam-result-item .folder{
  &{
    padding: 0;
    margin: 0;
    width: 38%;
  }
  .used-seedlist{
    &{
      display: inline-block;
      color: #6f7588;
      font-size: 12px;
      margin-left: 1px;
      opacity: .6;
    }
  }
}

.spam-result-item{
  .subject{
    .auto{
      &{
        margin-right: 8px;
        background: #bfbfbf;
        border-radius: 4px;
        padding: 2px 5px;
        font-size: 12px;
        color: #fff;
      }
    }
  }
}

.folder{
  .score{
    &{
      margin-right: 10px;
      padding: 6px 14px!important;
      border-radius: 17px !important;
      margin-top: 18px !important;
      font-weight: 500;
    }
  }
}

.links-analysis-modal{
  &{

  }
  .modal-dialog, .modal-content{
    &{
      max-width: 900px;
    }
  }
}

.message-design{
  &{

  }
  h1{
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding-left: 10px;
    padding-bottom: 20px;
  }
  .txt{
    padding: 20px;
    padding-left: 10px;
  }
  .from{
    border-bottom: 1px solid #ccc;
    padding: 5px 10px 14px 10px;
  }
}


.table-results{
  &{
    margin-bottom: 0!important;
  }
  tr:first-child td{
    border-top: 0px!important;
  }
  .account-desc{
    padding-top: 5px;
    display: block;
    font-weight: 500;
  }
  .hour{
    max-width: 153px;
    padding-top: 25px!important;
  }

  .inbox-type{
    &{
      width: 250px;
    }
  }
}

.templates{
  &{
    margin-top: 0!important;
    padding-right: 0;
  }
  .btn-header {
    margin-top: 20px;
  }

  .inbox-rate-nb{
    font-size: 45px;
    margin: 36px 0 16px;
  }
}

.message-design{
  table{
    td{
      border-top: 0;
    }
  }
}

.test-from-email{
  &{
    background: #00000012;
    display: initial;
    padding: 5px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    float: left;
    color: #6f7588;
    margin-top: 10px;
  }
}

.spam-result-item{
  margin-top: 30px;
}

.no-padding-top{
  padding-top: 0!important;
}

.second-title{
  &{
    float: left;
    margin-bottom: 25px;
  }
  .seedlist-raw{
    &{
      margin-left: 10px;
      font-size: 12px;
      color: #6f7588;
      opacity: 0.6;
    }
  }
}

.load-next-tests{
  &{
    margin-top: 30px;
    float: left;
    width: 100%;
  }
  .load-next-tests-btn{
    &{
      text-align: center;
      background: #d9dfe4;
      border: 0;
      color: #818797;
      font-weight: 500;
      padding: 8px 20px;
      border-radius: 24px;
      opacity: 0.8;
    }
    &:hover{
      opacity: 1;
    }
  }
}

.capitalize{
  &{
    text-transform: capitalize!important;
  }
}

.select-test-type{
  &{
    list-style: none;
    text-align: center;
    display: inline-flex;
  }
  button{
    &{
      float: left;
      margin: 30px;
      padding: 25px;
      font-size: 20px;
      border: none;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
}


.test-type-dropdown{
  &{
    max-width: 290px;
  }
}

.template-home{
  h2{
    margin-top: 31px;
  }
  #dropdown-basic{
    &{
      margin-top: 21px;
      margin-bottom: 0;
    }
  }
}

.text-type-badge{
  &{
    color: #fff!important;
    background-color: #19c797!important;
    border-color: #19c797!important;
    float: left;
    padding: 4px 10px;
    border-radius: 7px;
    font-weight: 500;
    margin: 26px;
    opacity: 1;
  }
  &.all{
    color: #f4756e!important;
    background-color: #fee5e4!important;
    border-color: #f4756e!important;
  }
  &.btoc{
    color: #da9e53 !important;
    background-color: #fcebcc !important;
    border-color: #da9e53 !important;
  }
  &.btob{
    color: #5c98df!important;
    background-color: #e2edf9!important;
    border-color: #5c98df !important;
  }
  .help-bubble-dropdown{
    &{
      margin-right: 10px;
      margin-left: 0;
    }
  }
  .fa-angle-down{
    &{
      margin-left: 10px;
    }
  }
}

.blured{
  &{
    filter: blur(10px);
  }
}

.requested-type-selector{
  &{
    max-width: 750px;
    position: absolute;
    top: 35%;
    z-index: 999;
    left: 28%;
    text-align: center;
    background: #000000ba;
    padding: 30px;
    border-radius: 20px;
    color: #fff;
  }
  .text-type-badge{
    margin-left: 32%;
    margin-bottom: 24px!important;
  }
  .help-bubble-dropdown{
    cursor: pointer;
  }

  .selector{
    &{
      color: #fff;
      border: none;
      background: #000;
      padding: 10px;
      border-radius: 6px;
      margin: 14px 14px 20px;
      padding: 7 px 20 px !important;
      font-size: 14px!important;
      border: none;
      font-weight: 500;
      padding: 13px 16px;
      border-radius: 25px;
      font-size: 14px!important;
      font-weight: 500;
    }
    &.red{
      color: #f4756e;
      background-color: #fee5e4;
    }
    &.blue{
      color: #5c98df;
      background-color: #e2edf9;
    }
    &.yellow{
      color: #da9e53;
      background: #fcebcc;
    }
  }
}

.nav-error-count{
  &{
    background: #f7bf23;
    width: 22px;
    height: 22px;
    text-align: center;
    margin-left: 5px;
    margin-top: 5px;
    color: #fff;
    font-size: 15px;
    position: absolute;
    line-height: 24px;
    border-radius: 50%;
  }
}

.repeat-period{
  input{
    &{
      width: 65px!important;
      text-align: center;
      padding: 16px!important;
      margin-bottom: 0!important;
      float: left;
    }
  }
  select{
    &{
      width: 115px;
      padding: 16px;
      margin-left: 10px;
      margin-bottom: 0!important;
      float: left;
    }
  }
}


.spamtest-auto-schedule-preview{
  &{
    font-weight: 400;
    color: #11113b;
  }
}

.run-autofix-ai{
  &{

  }
  h2{
    &{
      color: #fff;
      width: 100%;
    }
  }
}

.spammy-elements-list{
  color: red;
  font-size: 15px;
}

.beta-autofix{
  &{
    background: #11113b;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 0px;
    margin-top: 4px;
    position: absolute;
  }
}
.spamcheck-autofix-details{

  .see-email{
    &{
      float: right;
      padding-top: 33px;
    }
  }
}

.autofix-loading-no-card{
  &{
    background: transparent;
    border: none;
    box-shadow: none;
  }
  &.no-padding-left{
    &{
      padding-left: 0;
    }
    .txt{
      padding-left: 0!important;
    }
  }
  .txt{
    &{
      padding-left: 17px;
    }
  }
  .loader{
    &{
      float: left;
      margin: 3px 10px;
    }
  }
}

.card-autofix-progress{
  .autofix-result-title{
    &{
      margin-top: 0;
      width: 100%;
      margin-bottom: 25px;
    }
  }
  .score.running{
    &{
      margin-top: 18px;
      margin-right: 14px;

    }
    &.second{
      &{
        margin-top: 26px!important;
      }
    }
  }
  .txt.text-left.col-md-10{
    &{
      padding-left: 17px;
      width: 86%;
    }
  }

  .second-title{
    &{
      font-size: 15px;
    }
  }

  .table-results tr td:first-child {
    padding-left: 0 !important;
  }

  .table-results tr td:last-child {
    text-align: right;
    padding-right: 0 !important;
  }

  .completed-btn-ai{
    &{
      display: inline-block;
      padding: 5px 15px;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      font-size: 15px;
      margin-top: 14px;
      margin-right: 14px;
    }
    &.red-bck{
      &{
        background: #ff1d51;
      }
    }
    &.orange-bck{
      &{
        background: #FCAB10;
      }
    }
    &.green-bck{
      &{
        margin-top: 15px;
      }
    }
  }
}

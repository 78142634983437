.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: 'SF Pro Text';
  src: url('https://uploads-ssl.webflow.com/6336c0ecc4a07d5c2a6b36c2/666dd39cfbe248adfc254390_sf-pro-text-11%20(1).woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url('https://uploads-ssl.webflow.com/6336c0ecc4a07d5c2a6b36c2/666dd3e797e4ee47d6798b99_sf-pro-text-7%20(1).woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url('https://uploads-ssl.webflow.com/6336c0ecc4a07d5c2a6b36c2/666dd45c47fae1760ac5d025_sf-pro-text%20(1).woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url('https://uploads-ssl.webflow.com/6336c0ecc4a07d5c2a6b36c2/666a0a0c969bfc633104316f_sf-pro-text-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

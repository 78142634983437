.joinbutton{
  &{
    width: 100%;
    color: #fff;
    background-color: #3d46fb;
    border-color: #3d46fb;
  }
  &:hover{
    color: #fff;
  }
}

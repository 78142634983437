.card.spam-auto-item .btn {
  margin-top: 0px!important;
}

.spam-auto-item{
  .dropdown-date-filter .delete{
    &{
      color: #ff1d51;
    }
  }
}

.automated-test-create{
  .app.container{
    &{
      padding-top: 0;
      padding: 0;
    }
  }
  .auto-connect-title{
    &{
      margin-top: 62px;
      margin-bottom: 35px;
    }
  }
  .title{
    h1{
      &{
        margin-top: 0;
      }
    }
  }
  .search{
    input{
      &{
        width: 100%;
        padding-left: 65px!important;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

@media only screen and (max-width: 470px) {
  .frequency-page{

  }
}

.alert-purple-light{
  &{
    color: #434159;
    background-color: rgb(250, 246, 255);
    border-color: #eceaff;
  }
}

.spamchecker-auto-creator{
  .message-design h1{
    &{
      font-size: 23px;
    }
  }
}

.loading-padding-text{
  &{
    padding: 55px;
  }
}


.selected-account-email{
  &{
    margin: 0;
    padding: 18px;
    margin-top: 2px;
    font-size: 15px;
    border: none;
    border-radius: 10px;
    background-color: #fff;
    color: #18164c;
    width: 98%;
    border: 1px solid #e0e5e9;
    box-shadow: 0 2px 4px 0 rgba(1, 1, 2, 0.05);
    cursor: pointer;
  }
  i{
    &{
      cursor: pointer;
      float: right;
          margin-top: 4px;
    }
  }
}

p.spamtest-auto-schedule-preview{
  &{
    line-height: 30px;
  }
}

.run-immediately-modal .modal-dialog{
  &{
    max-width: 550px;
  }
}

.accounts-selector-list{
  &{
    float: left;
    list-style: none;
    width: 100%;
    padding-left: 0;
    max-height: 300px;
    overflow: auto;
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.08), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
    margin-top: -29px;
    border-radius: 6px;
    padding: 7px;
    width: 98%;
    background: #fff;
  }
  li{
    &{
      border-radius: 8px;
      padding: 11px;
      line-height: 28px;
      margin-bottom: 3px;
      cursor: pointer;
    }
    &.selected{
      &{
        background: #f6f5ff;
      }
    }
    &:hover{
      &{
        background: #f6f5ff;
      }
    }
    .btn{
      &{
        float: right;
        padding: 5px 16px!important;
        margin-top: 0;
        font-size: 12px!important;
      }
    }
  }
}

.linked-test-number{
  &{
    padding: 10px 0px;
  }
}

.info-frequencies{
  &{
    margin-left: 15px;
  }
}

.automated-test-accounts{
  .compressed-view .card .btn {
    padding: 3px 17px !important;
  }
}

.automated-spamcheck{
  .alert-purple-light{
    ol{
      &{
        padding-left: 16px;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}


.progress-automated-test{
  &{
    width: 100%;
    position: relative;
    height: 50px;
    margin-bottom: 25px;
    margin-top: 35px;
  }
  ul{
    &{
      list-style: none;
      width: 100%;
      padding: 0;
    }
    li, a{
      &{
        float: left;
        /* font-size: 25px; */
        width: 18.4%;
        /* padding: 4px; */
        height: 6px;
        background: #f4f8fc;
        text-align: center;
        border-radius: 5px;
        /* border: 1px solid #11113b; */
        margin: 0 1%;
        z-index: 1;
        position: relative;
        background: #e5e5e5;
      }
      &.selected{
        background: #11113b;
        color: #11113b;
      }
      &.done{
        background: #11113b;
        color: #fff;
        cursor: pointer;
      }
      &.done:hover{
        height: 9px;
        margin-top: -1px;
      }
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
        margin-left: 1%;
      }
    }
  }

  .bar{
    &{
      display: none;
      height: 6px;
      width: 100%;
      background: #11113b;
      border-radius: 4px;
      position: absolute;
      z-index: 0;
      margin-top: 20px;
    }
  }
}

.targets{
  div{
    &{
      max-width: 400px;
      background: #fff;
      border-radius: 4px;
      float: left;
      width: 100%;
      text-align: center;
      height: 300px;
      margin: 20px;
      cursor: pointer;;
    }
    &.selected{
      border: 2px solid;
    }
  }
}

.link-back-test-results{
  &{
    margin-top: 24px;
  }
}

.launch-spamtest-tuto-new{
  &{
    margin-top: 0;
  }
}

.spam-auto-item{
  .last-test-result{
    margin-top: 11px;
  }
  .last-run-at{
    &{
      padding-top: 13px;
    }
  }
  .switch-off-on{
    &{
      padding-top: 13px;
    }
  }
}


.btn.grey{
  &{
    text-align: center;
    background: #d9dfe4;
    border: 0;
    color: #818797;
    font-weight: 500;
    opacity: 0.8;
  }
}

.email-style .format-selector{
  &{
    margin-top: -10px;
  }
}

.repeat-day{
  .col-md-12{
    padding-left: 0;
    margin: 15px 0;
  }
  .col-md-5{
    &{
      padding: 0;
      margin: 0;
    }
  }
  .tag{
    &{
      background: #11113b;
      color: #fff;
      padding: 6px 9px;
      border-radius: 8px;
      margin-right: 10px;
    }
  }
}

.alerts-test-card{
  &{

  }
  h4{
    &{
          margin-top: 10px;
    }
  }
  .fieldCont, .col-md-6{
    &{
      padding-left: 0;
    }
  }
  p{
    &{
      opacity: 0.8;
    }
  }

  select{
    &{
      padding: 13px 15px;
    }
  }
}

.notif-chan{
  &{
    margin-bottom: 40px;
  }
}

.automated-test-creation-date{
  &{
    opacity: 0.8;
  }
}

.small-slack-btn{
  &{
    align-items:center;
    color:#000;
    background-color:#fff;
    border:1px solid #ddd;
    border-radius:4px;display:inline-flex;
    font-size:16px;
    height:48px;justify-content:center;text-decoration:none;
    padding: 11px;
  }
  svg{
    &{
      height:20px;width:20px;margin-right:12px
    }
  }
  &:hover{
    text-decoration: none;
  }
}

.automated-spamcheck{
  &{
  }
  .requested-type-selector{
    &{
      position: relative;
      left: 0%;
      background: transparent;
      max-width: 100%;
      color: #757f8b !important;
      text-align: left;
      padding: 10px 10px;
    }
    .seedlist-radio{
      &{
        margin-top: 10px;
        padding-right: 15px;
      }
    }
    .seedlist-desc-opt{
      &{
        padding-left: 28px;
        margin-top: -8px;
      }
    }
    .seedlist-select{
      &{
        width: 282px;
        margin-top: 10px;
        margin-left: 23px;
      }
    }
  }
  b{
    &{
      font-size: 16px;
      padding-top: 10px;
      display: block;
    }
    &.little{
      font-size: 14px;
      padding-top: 0;
    }
  }
  .selector.blue{
    &{
      margin-left: 0;
    }
  }
  .selected{
    &{
      border: 2px solid #11113b;
    }
  }
}

.spamcheck-repeat-day{
  &{
    list-style: none;
    margin-top: 10px;
    padding-left: 0;
  }
  li{
    &{
      float: left;
      background: #f4f8fc;
      width: 25px;
      height: 25px;
      text-align: center;
      margin: 5px;
      font-weight: 500;
      border-radius: 50%;
      font-size: 13px;
      padding-top: 3px;
      cursor: pointer;
    }
    &.selected{
      &{
        color: #fff;
        background: #3d46fb;
      }
    }
  }
}

.automated-spamtest-btns{
  &{
    margin-top: 20px;
    float: left;
    width: 100%;
  }
  .btn{
    margin: 0 10px;
  }
}

.frequency-page{
  form.col-md-6{
    padding-left: 0px;
  }
  .hour-dropdown-select{
    &{
      margin-right: 10px;
    }
  }

  select{
    &{
          padding: 16px;
    }
  }
}

.repeat-day{
  .alert-no-margin.yellow.red-alert .fa-circle-info{
    &{
      margin-top: 0;
    }
  }
}

.no-list-style{
  &{
    list-style: none;
  }
}
.settings-modal{
  .modal-dialog{
    max-width: 930px;
  }
  .footer{
    .btn-primary{
      margin-right: 30px;
    }
  }
  .pilot-head{
    &{
      padding-bottom: 30px;
    }
    .slider{
      &{
        margin-top: 30px;
      }
      p{
        width: 80%;
        margin: 0 auto;
      }
    }
  }
  .pilot-manual{
    padding: 30px 0;
    border-top: 1px solid #e0e5e9;
  }
  .top-margin{
    padding-top: 20px;
  }
  .help-bubble{
    display: inline-block;
    float: none;
  }
  .increase{
    &{
      padding-right: 40px;
    }
  }
  .max{
    &{
      padding-right: 40px;
    }
  }
 .light{
    opacity: 0.6;
 }
 .max-conversation-running-slider-title{
  max-width: 238px;
  margin: 0 auto;
  margin-bottom: 25px;
 }
 .max-conversation-running-slider{
   max-width: 600px;
   margin: 0 auto;
 }
}

.prevent-stop{
  &{
    max-width: 90%!important;
  }
}

.prevent-goodby{
  &{
    font-size: 15px;
    line-height: 1.2;
    text-align: left;
    font-weight: 400;
  }
}

.warming-only-on{
  ul{
    &{
      list-style: none;
      float: right;
      margin-bottom: 0;
    }
    li{
      &{
        list-style: none;
        float: left;
        padding: 5px;
        margin: 0px 5px;
        border: 2px solid #ccc;
        border-radius: 30px;
        cursor: pointer;
      }
      &.selected{
        &{
          border-color: #6742ed;
        }
      }
      .name{
        padding: 4px;
        float: left;
      }
      img{
        height: 30px;
        border-radius: 50%;
      }
    }
  }
}

.help-bubble{
  &{
    float: left;
    margin: 0 10px;
  }
  i{
    &{
      font-size: 18px;
      cursor: pointer;
    }
    &:hover{
      color: #3d46fb;
    }
  }
}

.reputation-modal{
  .del{
    .btn{
      margin: 10px;
    }
    .btn[disabled]{
      color: #fff!important;
    }
  }
}

.content-code{
  &{
    margin-bottom: 0;
  }
  .copy-code{
    margin-top: -2px;
    margin-bottom: 0;
  }
}

.copy-code{
  &{
    background-color: #fff!important;
    color: #11103b!important;
    border-radius: 8px;
    margin: 15px 0px;
    font-size: 15px;
    cursor: pointer;
    display: inline-flex;
    margin-top: 15px;
    font-weight: 500;
    border: 1px solid #ccc;
    white-space: pre-line;
  }
  .code, .list{
    padding: 0px 10px;
  }
  .copy{
    &{
      float: left;
      font-size: 16px;
      margin-left: 0px;
      border-left: 1px solid #ccc;
      background: #f8f9fb;
      color: #10113b;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 0px 16px;
    }
  }
}

.upgrade-spamchecker{
  &{
    color: #fff;
    background: #11103b;
    margin-bottom: 40px;
    position: relative;
  }
  a{
    color: #fff;
    text-decoration: underline;
  }
  p{
    font-size: 16px;
  }
  h2{
    margin-bottom: 25px;
  }
  img{
    width: 210px;
  }
  .btn{
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
    opacity: 1;
    text-decoration: none;
  }

  .col-md-9{
    &{
      padding: 0;
    }
  }

  .close{
    &{
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 9999;
      color: #fff;
      font-size: 14px;
      opacity: 0.8;
      font-size: 17px;
    }
    &:hover{
      opacity: 1;
    }
  }

  .col-md-3{
    .btn{
      margin-top: 30px;
    }
  }
  p{
    margin-bottom: 15px;
  }
  .loader{
    &{
      margin-top: 38px;
    }
  }
}

.green-bck{
  &{
    background-color: #28b970;
    opacity: 1;
  }
  &:hover, &:active:focus, &:focus{
    background-color: #21a16b!important;
  }
}

.remaining-credits{
  text-align: center;
  background: #d9dfe4;
  border: 0;
  color: #11103b;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 24px;
  opacity: 0.8;
  float: left;
  font-size: 13px;
  margin: 21px 20px 0px 30px;
}

.launch-spamtest-tuto{
  &{
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
  }
  span.step{
    color: #3d46fb;
    padding: 4px 9px;
    border-radius: 9px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 17px;
  }
  li{
    &{
      font-size: 15px;
      margin: 30px 0;
    }
    .btn{
      margin-top: 0;
    }
    select{
      &{
        padding: 7px 20px;
        border-radius: 6px;
        border-color: #0000002e;
      }
    }
  }
  .help-bubble{
    &{
      display: inline-flex;
      float: none;
    }
  }
}


.launch-spamtest-tuto-new{
  .selector{
    &{
      margin-left: 0;
      padding-left: 0;
      margin-bottom: 15px;
      margin-top: -5px;
      font-size: 12px;
    }
    select{
      &{
        border: none;
      }
    }
  }
  .green{
    &{
      background-color: #26c180;
    }
  }
  .btn-primary{
    border: none;
    margin: 15px 0px 10px 0px!important;
        box-shadow: 0 2px 4px 0 rgba(1, 1, 2, 0.05);
    text-transform: uppercase;
  }
  h3{
    &{
      font-size: 16px;
    }
    .vl{
      color: #11103b!important;
    }
  }
  .emails-list{
    &{
      max-width: 59%;
      height: 77px;
    }
    .list{
      font-weight: normal;
      overflow: scroll;
      overflow-x: hidden;
      line-break: anywhere;
      font-size: 13px;
      width: 700px;
    }
    .copy{
      float: left;
      font-size: 30px!important;
      margin-left: 0px;
      border-left: 1px solid #ccc;
      background: #f8f9fb;
      color: #10113b;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 19px 25px !important;
      //width: 20%;
    }

  }
  .email-code, .cde{
    &{
      background-color: #fff!important;
      color: #11103b!important;
      border-radius: 8px;
      margin: 15px 0px;
      font-size: 15px;
      cursor: pointer;
      display: inline-flex;
      margin-top: 15px;
      font-weight: 500;
      border: 1px solid #ccc;
      white-space: pre-line;
    }
    .code, .list{
      padding: 12px;
    }
    .copy{
      &{
        float: left;
        font-size: 16px;
        margin-left: 0px;
        border-left: 1px solid #ccc;
        background: #f8f9fb;
        color: #10113b;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 10px 16px;
      }
    }
  }
}

.welcome {
  padding: 20px; }

.welcome .card {
  padding: 30px; }

.welcome .beer {
  width: 110px;
  margin: 0 auto; }

.welcome .beer img {
  width: 110px; }

.welcome h2 {
  text-align: center;
  float: none; }

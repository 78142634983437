.StripeElement {
  box-sizing: border-box;
  height: 50px;
  padding: 15px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #e0e5e9;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  margin: 0px 17px 30px 17px; }

.StripeElement--focus {
  border-color: #18164c; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

.add-credit-card .descprice .col-md-11 {
  padding-top: 0; }

.add-credit-card .descprice p {
  font-size: 14px;
  margin: 0; }

.add-credit-card .secure-payment {
  background: #d6ffe6;
  padding: 12px;
  color: #191919;
  border-radius: 4px;
  margin-bottom: 30px; }

.add-credit-card h2 {
  padding: 22px; }

.subscribe {
  margin: 0 auto; }

.subscribe .btn {
  color: #fff;
  background-color: #3d46fb;
  border-color: #3d46fb;
  opacity: 1; }

.subscribe .success-p {
  font-size: 17px;
  padding: 30px; }

.unlimited {
  font-size: 35px;
  color: #3d46fb;
  border-color: #3d46fb;
  border: 1px solid;
  padding: 20px;
  border-radius: 10px;
  /* float: left; */
  display: inline-block;
  margin-top: 16px; }

.free {
  font-size: 20px;
  color: #18174e;
  border-color: #18174e;
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  /* float: left; */
  display: inline-block;
  margin-top: 0px; }

.nav-left {
  list-style: none;
  padding: 0; }

.nav-left li {
  padding: 5px;
  float: left;
  width: 100%; }

.nav-left li i {
  margin-right: 5px; }

.nav-left li a {
  font-weight: 400;
  font-size: 15px;
  color: #6f7588; }

.nav-left li a:hover {
  text-decoration: none; }

.nav-left li .signout:hover {
  color: #ff1d51; }

.invoices {
  list-style: none;
  padding: 0; }

.invoices li {
  width: 100%;
  float: left;
  padding: 10px;
  border-bottom: 1px solid #0000001f; }

.invoices li a {
  font-weight: bold; }

.checkups{
  .card{
    .fa-check-circle{
      margin-right: 10px;
      color: #2fd059;
      font-size: 34px;
      line-height: 25px;
      float: left;
      cursor: pointer;
    }

    .far{
      margin-top: 5px;
      font-size: 28px;
    }

    .fa-times-circle{
      margin-right: 10px;
      color: #ff1d51;
      line-height: 25px;
      float: left;
      cursor: pointer;
    }

    .fa-dot-circle{
      margin-right: 10px;
      color: #fd8831;
      line-height: 25px;
      float: left;
      cursor: pointer;
    }
  }

  .btn-header{
    &{
      margin-top: 15px;
    }
  }
  .last-refresh{
    &{
      float: left;
      margin: 30px 30px 0px 0px;
    }
  }

  .last-days-filter{
    &{
      margin-right: 0px;
    }
    .dropdown-toggle{
      &{
        margin-top: -16px!important;
      }
    }
  }

  .copilot-insights{
    &{
      height: 334px;
    }
    .copilot-txt-insights{
      &{
        margin-top: 5px;
        display: inline-block;
      }
    }
  }
}


.blacklists-modal{
  .modal-dialog{
    &{
      max-width: 630px;
    }
  }
  .red{
    color: #ff1d51;
  }
  .green{
    color: #2fd059;
  }
  img{
    width: 40%;
  }
  p{
    font-size: 17px;
    margin: 25px 8px;
    line-break: auto;
  }
  table{
    tbody{
      &{
        font-size: 17px;
      }
    }
  }
  .alert.warning{
    padding: 20px 7px;
    margin-bottom: 45px;
  }
}

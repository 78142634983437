@media only screen and (max-width: 470px) {
  .alert.status{
    &{
      float: left;
    }
  }
  .alert.status .btn{
    &{
      margin-top: 15px;
    }
  }
  .tags{
    &{
      display: none;
    }
  }

  .hidden-mobile{
    &{
      display: none!important;
    }
  }

  .sidebar{
    .ct{
      .logo{
        &{
          width: 0px;
          display: none;
        }
      }
      .main-nav{
        &{
          margin-left: auto;
        }
      }
    }
  }

  .nbstats .col-md-3{
    &{
      width: 96%!important;
      margin: 2%!important;
    }
  }

  .home{
    .search{
      input{
        &{
              width: 100%;
        }
      }
    }
  }

  .modal-content, .fix-issue-modal .modal-content, .fix-issue-modal .modal-dialog{
    &{
      max-width: 99%!important;
      width: 99%!important;
    }
  }

  .no-padding-left {
    padding-left: 16px!important;
  }

  .no-padding-right {
    padding-right: 16px!important;
  }

  .row{
    margin-left: 0!important;
    margin-right: 0!important;
  }

  .provider .card{
    &{
      width: 100%!important;
      height: 300px!important;
      text-align: center!important;
    }
  }

  .account-page .notifications-full{
    &{
      margin-left: 0;
      margin-right: 0;
    }
  }

}

.settings-modal{
  .MuiSlider-thumb{
    margin-top: -4px!important;
  }
}

.row{
  h2{
    color: #18164c;
    font-size: 21px;
  }
  .full{
    width: 100%;
  }
}

.is-loading{
  &{
    background: #eee!important;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)!important;
    background-size: 200% 100%!important;
    animation: 1.5s shine linear infinite!important;
    height: 108px!important;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}


.settings-modal.recpt{
  .modal-dialog{
    overflow: hidden;
    max-width: 790px;
  }

  .datac{
    &{
      padding: 30px 0px;
    }
    h1{
      color: #18164c;
      margin-top: 10px;
    }
  }

  .red-alert.yellow{
    &{
      background: #fff !important;
      color: #6f7588;
      text-align: left;
      padding: 10px;
      margin: 20px 0 20px 0;
      font-size: 14px;
      padding-left: 0;
    }
    i{
      color: #fad067 !important;
      margin-right: 15px;
      margin-left: 0px;
      margin-top: 8px;
      float: left;
      font-size: 21px;
    }
    p{
      &{
        margin: 0;
        float: left;
        width: 90%;
      }
    }
    a{
      &{
        text-decoration: underline;
      }
    }
  }

  .beta{
    &{
      float: right;
          font-size: 14px;
          background: #11113b !important;
          color: #fff;
          padding: 5px 10px;
          border-radius: 6px;
          margin-top: 7px;
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
          right: -90px;
          position: absolute;
          top: 3px;
          height: 24px;
          width: 194px;
          transform: rotate(45deg);
          background-color: rgba(0, 0, 0, 0.3);
          text-align: center;
    }
  }

  .recipe-infos{
    &{
      margin-top: 20px;
    }
  }

  .head-p{
    &{
      margin-top: 16px;
      margin-bottom: 0;
    }
  }

  .warning.card.bounce{
    text-align: left;
    padding: 20px;
    display: inline-block;
  }

      h4{
        margin-top: 40px;
      }
  .receipt{
    &{
      background:  #11103b;
      color: #fff;
      padding: 20px;
      border-radius: 4px;
      margin: 10px 0 20px 0;
    }

    .opener{
      &{
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
      }
    }
    i{
      float: right;
      font-size: 22px;
    }

    p{
      line-height: 30px;
    }

    .updated-value{
      &{
        background: #fff;
        color: #11103b!important;
        padding: 3px 10px;
        border-radius: 4px;
        margin: 0 5px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}

.notifications-full{
  &{
    margin-left: -15px;
    margin-right: -15px;
  }
}


.account-page{
  &{
    padding-top: 15px;
  }
}
p.delete{
  &{
      font-size: 14px;
      line-height: 36px;
      padding-top: -2px;
      padding-top: 3px;
      margin-bottom: 3px;
  }
}

.tox-tinymce{
  &{
    margin-bottom: 15px;
  }
}

.sub-nav{
  &{
    background: #fff;
    width: 100%;
    padding: 8px;
    margin-top: 60px;
  }

  .provider-ico{
    &{
      height: 13px;
      border-radius: 4px;
      margin-left: 10px;
      margin-top: -3px;
    }
  }

  .badge-provider{
    &{
      display: block;
      float: inline-end;
      font-size: 12px;
      border: 1px solid #ccc;
      padding: 0px 11px;
      line-height: 24px;
      margin-left: 20px;
      border-radius: 5px;
      margin-top: 5px;
      background: #EEEEEE;
      color: #666666;
      border: none;
    }
  }
  .ct{
    &{
      width: 1170px;
      margin: 0 auto;
    }
    .account-name{
      &{
        font-size: 18px;
        line-height: 35px;
      }
      .status{
        &{
          float:left;
          margin: 12px 12px 0 0px;
        }
        .stat{
          &{
            width: 10px;
            height: 10px;
            float: left;
            border-radius: 50%;
            cursor: pointer;
          }
          &.alive{
            box-shadow: 0px 0px 3px 0px #1ac797;
            background: #1ac797;
          }
          &.ko{
            box-shadow: 0px 0px 3px 1px #ff1d51;
            background: #ff1d51;
          }
          &.kok{
            box-shadow: 0px 0px 3px 1px #ff701d;
            background: #ff701d;
          }
        }
      }
    }
    ul{
      &{
        list-style: none;
        margin: 0;
      }
      .has-notif{
        padding-right: 33px!important;
      }
      li{
        &{

        }
        &:last-child{
          a{
            padding-right: 5px;
          }
        }
        a{
          &{
            color: #6f7588;
            font-size: 15px;
            font-weight: 500;
            line-height: 35px;
            padding: 0px 25px;
          }
          &:hover,&:focus{
            color: rgba(0,0,0,.8);
            text-decoration: none;
          }
          &.active{
            &{
              color: #18164c;
            }
            i{
              color: #3d46fb;
            }
          }
          i{
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.content-ref-badge{
  &{
    border: 1px solid;
    padding: 6px 10px!important;
    border-radius: 10px!important;
    margin: 0;
    margin-top: 6px;
  }
}

.card h2.title{
  &{
    color: #18164c;
    font-size: 20px;
    margin-top: 10px;
  }
  .nb{
    color: #3d46fb!important;
  }
}

.violated-rule{
  .hight{
    &{
      padding: 5px;
      background: #ff1d51;
      color: #fff;
      border-radius: 4px;
      margin: 2px 12px 0px 0px;
      float: left;
    }
  }
}

h3.title{
  &{
    color: #18164c;
    font-size: 17px;
    margin-top: 14px;
    float: left;
  }
}

.col-md-12{
  .spamlist{
    &{
      float: left;
      width: 100%;
    }
    .violated-rule{
      p{
        width: 90%;
        padding-top: 7px;
      }
    }
    .empty{
      &{
        width: 100%;
      }
      img{
        &{
          max-width: 25%;
        }
      }
    }
  }
}
.spamlist{
  &{
    margin: 0;
    padding: 0;
    padding-top: 14px;
    float: left;
    margin-bottom: 10px;
  }
  li{
    &{
      list-style: none;
      padding: 10px;
      font-size: 17px;
      margin: 5px;
      float: left;
      background: #ff1d51;
      color: #fff;
      border-radius: 5px;
    }
  }
  .violated-rule{
    p{
      &{
        float: left;
        width: 292px;
      }
    }
  }
  .empty{
    img{
      &{
        max-width: 75%;
      }
    }
    p{
      margin-top: 25px;
    }
  }
  .info{
    &{
      margin-top: 40px;
    }
  }
}

.dropdown-providers{
  &{
    width: 240px;
  }
}
.dropdown-date-filter{
  &{
    margin-top: 7px;
  }
  &:focus{
    outline: none;
  }
  button{
    &, &:active, &:hover, &:active:focus, &:focus{
      border: 1px solid #e0e5e9;
      color: #6f7588;
      padding: 7px 20px !important;
      font-size: 14px!important;
      margin-top: 13px;
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(1, 1, 2, 0.05);
      outline: none;
    }
  }
  a{
    &{
      float:left;
      width: 100%;
      padding: 10px;
      color: #6f7588;
    }
    &:hover{
      color: #6f7588;
      background: #f3f3f3;
      text-decoration: none;
    }
  }
  .dropdown-menu{
    border-radius: 10px!important;
  }
}

.last-days-filter{
  &{
    margin-right: 25px;
  }
}
.rate-desc{
  .col-md-2{
    &{
      padding: 0;
    }
  }
  a{
    &{
      text-decoration: underline;
    }
  }
  .badge{
    &{
      font-weight: 500;
      padding: 7px 15px;
      margin-top: 7px;
      text-transform: capitalize;
    }
    &.bad{
      background: #ff1e51;
    }
    &.average{
      background: #ff9b1e;
    }
    &.good, &.great{
      background: #00d463;
    }
  }
}

.card{
  .title{
    .btn{
      font-size: 15px!important;
      padding: 6px 18px !important;
      margin-top: -8px;
    }
  }
  .pie{
    width: 94%;
    padding: 3%;
  }
}

.nbstats{
  &{
    padding-left: 0;
    padding-right: 0;
    margin: 30px 0;
  }
  .col-md-3{
    border: none;
    box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.08), 0 3px 6px 0 rgba(0,0,0, 0.12);
    background: #fff;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 13px;
    width: 273px;
    margin: 0px 13px;
    flex-direction: unset;
  }
  .col-md-3:first-child {
    margin-left: 0;
  }
  .col-md-3.last {
    margin-right: 0;
  }
  .bl{
    &{
          text-align: left;
    }
    .nb{
      width: 100%;
      float: left;
      font-size: 40px;
    }
    .txt{
      .far, .fas{
        width: 25px;
        height: 25px;
        padding: 5px;
        background: #000;
        color: #fff;
        border-radius: 50%;
        margin-right: 7px;
      }
      .fa-paper-plane{
        background: #41d9cc3d;
        color: #41d9cc;
      }
      .fa-inbox{
        background: #3ca5ff4d;
        color: #3ca5ff;
      }
      .fa-capsules{
        padding: 5px 6px;
        background: #e65f7e47;
        color: #e65f7e;
      }
      .fa-comments{
        background: #3d47fb38;
        color: #3d47fb;
      }
    }
  }
  .col-md-3:nth-child(4){
    .bl{
      &{
        border-right: 0px solid;
      }
    }
  }
}

.inbox-rate-nb{
  &{
    font-size: 117px;
    text-align: center;
    padding: 30px;
  }
}

.reputation-modal{
  .modal-dialog{
    &{
      margin-top: 6%;
    }
  }
  .modal-content{
    &{
      padding: 10px 20px;
      line-break: auto;
    }
    ul{
      padding-left: 20px;
    }
    img{
      max-width: 75%;
    }
    .txt{
      padding: 20px 0;
    }
  }
}

.line-break{
  &{
    line-break: anywhere;
  }
}

select.timezones{
  &{
    float: right;
    padding: 10px;
    border-radius: 22px;
    background: #ccc;
    border: 1px solid #e0e5e9;
    color: #6f7588;
    font-size: 17px;
    font-weight: 500;
    background-color: #f4f6f9;
    box-shadow: 0 2px 4px 0 rgba(1, 1, 2, 0.05);
    cursor: pointer;
  }
}

.no-padding{
  padding: 0;
}

.no-padding-left{
  padding-left: 0;
}

.no-padding-right{
  padding-right: 0;
}

.btn.purple{
  background-color: #3d46fb;
  border-color: #3d46fb;
}
#chartjs-tooltip{box-shadow:0 15px 30px 0 rgba(1,1,2,.1)}.tooltip-chart h3,.tooltip-chart p,.tooltip-chart span{font-family:Inter}

.score-calculating{
  &{
    font-size: 30px;
    padding: 85px 30px;
  }
  span{
    background: #dcdcdc;
    padding: 5px 15px;
    border-radius: 9px;
    animation: pulse 2s infinite;
  }
}

.score-blck{
  .card{
    &{
      height: 338px;
    }
  }
}


.aliases-select{
  &{
    max-width: 45%;
  }
  select{
    &{
      max-width: 100%;
    }
  }
}
